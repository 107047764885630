import { IPublicClientApplication } from '@azure/msal-browser';

export async function getAccessToken(instance: IPublicClientApplication) {
  const accessTokenRequest = {
    scopes: [`https://${process.env.REACT_APP_AZURE_B2C_TENANT}/api-missions/user_access`],
    account: instance.getActiveAccount()!,
  };

  return instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
    return accessTokenResponse.accessToken;
  });
}
