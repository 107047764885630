import Dropdown, { DropdownElement } from '../dropdown/Dropdown.component';
import i18n from '../../i18n/config';
import React, { useState, useEffect } from 'react';

const LanguageSelector = () => {
  const [selectedLanguage, setSelectedLanguage] = useState<DropdownElement | null>(null);
  const [languages, setLanguages] = useState<DropdownElement[]>([]);

  useEffect(() => {
    const languageOptions: DropdownElement[] = [
      { label: 'FR', value: 'fr' },
      { label: 'EN', value: 'en' },
    ];
    setLanguages(languageOptions);
    const currentLanguage =
      languageOptions.find((item) => item.value === i18n.language) || ({} as DropdownElement);
    setSelectedLanguage(currentLanguage);
  }, []);

  const handleLanguageSelection = (selectedOption: DropdownElement | null) => {
    if (selectedOption) {
      setSelectedLanguage(selectedOption);
      i18n.changeLanguage(selectedOption.value).then((t) => {
        i18n.reloadResources();
      });
    }
  };

  return (
    <div>
      <div id='language-selector' data-testid='language-selector'>
        <Dropdown
          elements={languages}
          handleSelectedElement={handleLanguageSelection}
          selectedElement={selectedLanguage}
          beforeIcon={null}
          placeHolder={`${selectedLanguage?.value}`}
          customClass='-lang'
          showScrollIndicators={false}
        />
      </div>
    </div>
  );
};

export default LanguageSelector;
