import React, { useEffect, useRef, useState } from 'react';
import calendar from '../../../../assets/images/calendar-edit.svg';

interface Props {
  id?: string;
  class_name?: string;
  onChange: (e: Date) => void;
  value?: Date;
}

const PickerDate: React.FC<Props> = (props) => {
  const [date, setDate] = useState('');
  const [is_placeholder_visible, set_placeholder_visible] = useState(false);
  const DATE_INPUT_REF = useRef<HTMLInputElement | null>(null);
  const [timer, setTimer] = React.useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    SET_PLACEHOLDER_DATE_FROM_DATE(props.value);
  }, [props.value]);

  const DATE: Date = new Date();
  const DAY: string = DATE.getDate().toString().padStart(2, '0');
  const MONTH: string = (DATE.getMonth() + 1).toString().padStart(2, '0');
  const YEAR: string = DATE.getFullYear().toString();
  const DATE_TODAY: string = YEAR + '-' + MONTH + '-' + DAY;

  const SET_PLACEHOLDER_DATE_FROM_DATE = (date: Date | undefined) => {
    if (date) {
      const activeDate = new Date(date);
      const activeDay: string = activeDate.getDate().toString().padStart(2, '0');
      const activeMonth: string = (activeDate.getMonth() + 1).toString().padStart(2, '0');
      const activeYear: string = activeDate.getFullYear().toString().padStart(4, '0');
      setDate(activeYear + '-' + activeMonth + '-' + activeDay);
      set_placeholder_visible(false);
    }
  };

  const HANDLE_FOCUS = () => {
    set_placeholder_visible(false);
    if (DATE_INPUT_REF.current) {
      DATE_INPUT_REF.current.focus();
    }
  };

  const HANDLE_BLUR = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      set_placeholder_visible(true);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    if (!newValue) {
      setDate('');
      return;
    }
    const [year, month, day] = newValue.split('-');
    if (year && month && day) {
      const newDate = new Date(`${year}-${month}-${day}`);
      if (!isNaN(newDate.getTime())) {
        SET_PLACEHOLDER_DATE_FROM_DATE(newDate);
        if (timer) {
          clearTimeout(timer);
        }
        const newTimer = setTimeout(() => {
          props.onChange(newDate);
        }, 300);
        setTimer(newTimer);
        return;
      }
    }
  };

  return (
    <div className='picker'>
      <input
        id={props.id ?? 'date'}
        data-testid={props.id ?? 'date'}
        data-id='date'
        className={is_placeholder_visible ? 'picker__input picker__input--white' : 'picker__input'}
        type='date'
        min={DATE_TODAY}
        ref={DATE_INPUT_REF}
        onFocus={HANDLE_FOCUS}
        onBlur={HANDLE_BLUR}
        onChange={onChange}
        placeholder='yyyy-mm-dd'
        name='date'
        value={date}
      />
      <img src={calendar} alt='calendar' className='picker__image' />
    </div>
  );
};

export default PickerDate;
