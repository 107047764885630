import React from 'react';

interface Props {
  label: string;
  htmlFor?: string;
  mandatory?: boolean;
}

const FormLabel: React.FC<Props> = (props) => {
  let htmlFor = {};
  if (props.htmlFor) {
    htmlFor = { htmlFor: props.htmlFor };
  }
  return (
    <label {...htmlFor} className={'form-label' + (props.mandatory ? ' form-label__required' : '')}>
      {props.label}
    </label>
  );
};

export default FormLabel;
