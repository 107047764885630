import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import PickerDate from '../../form/picker/picker_date/PickerDate.component';
import PickerTime from '../../form/picker/picker_time/PickerTime.component';
import IMission, { missionMandatory } from '../../../types/mission.type';
import FormLabel from '../../form/form-label/FormLabel.component';
import clock from '../../../assets/img/raw/clock.svg';

interface Props {
  mission: IMission;
  updateMission: (property: string, value: any) => void;
  duration?: boolean;
}
const Time: React.FC<Props> = (props) => {
  const [date, setDate] = useState<Date | undefined>(undefined);
  const [pickupTime, setPickupTime] = useState<Date | undefined>(undefined);
  const [dropoffTime, setDropoffTime] = useState<Date | undefined>(undefined);
  const [duration, setDuration] = useState<number | undefined>(undefined);

  useEffect(() => {
    setDate(new Date(props.mission.pickupDate));

    if (props.mission.pickupTime) {
      const pickupTime = new Date(props.mission.pickupTime);
      const adjustedPickupTime = new Date(Date.UTC(1970, 0, 1, pickupTime.getUTCHours(), pickupTime.getUTCMinutes(), pickupTime.getUTCSeconds()));
      setPickupTime(adjustedPickupTime);
    }

    if (props.mission.dropoffTime) {
      const dropoffTime = new Date(props.mission.dropoffTime);
      const adjustedDropoffTime = new Date(Date.UTC(1970, 0, 1, dropoffTime.getUTCHours(), dropoffTime.getUTCMinutes(), dropoffTime.getUTCSeconds()));
      setDropoffTime(adjustedDropoffTime);
    }

    if (props.mission.dropoffTime && props.mission.pickupTime) {
      const adjustedPickupTime = new Date(Date.UTC(1970, 0, 1, new Date(props.mission.pickupTime).getUTCHours(), new Date(props.mission.pickupTime).getUTCMinutes(), new Date(props.mission.pickupTime).getUTCSeconds())).getTime();
      let adjustedDropoffTime = new Date(Date.UTC(1970, 0, 1, new Date(props.mission.dropoffTime).getUTCHours(), new Date(props.mission.dropoffTime).getUTCMinutes(), new Date(props.mission.dropoffTime).getUTCSeconds())).getTime();

      if (adjustedDropoffTime < adjustedPickupTime) {
        adjustedDropoffTime = adjustedDropoffTime + 24 * 1000 * 60 * 60;
      }

      let durationCalc = adjustedDropoffTime - adjustedPickupTime;
      durationCalc = Math.ceil(Math.abs(durationCalc) / (1000 * 60 * 60));
      setDuration(durationCalc);
    }
  }, [props.mission]);

  return (
    <div className='time'>
      <h6 className='time__title'>
        <FormLabel
          label={t('estimate.time')}
          mandatory={
            missionMandatory.pickupDate ||
            missionMandatory.pickupTime ||
            missionMandatory.dropoffTime
          }
        />
      </h6>
      <div className='time__input'>
        <div>
          <FormLabel
            htmlFor='Date'
            label={t('form.time.date')}
            mandatory={missionMandatory.pickupDate}
          />
          <PickerDate
            id='pickupDate'
            onChange={(e) => {
              props.updateMission('pickupDate', e);
            }}
            value={date}
          />
        </div>
        <div>
          <FormLabel
            htmlFor='Date'
            label={t('form.time.from')}
            mandatory={missionMandatory.pickupTime}
          />
          <PickerTime
            id='pickupTime'
            onChange={(e) => {
              props.updateMission('pickupTime', e);
            }}
            value={pickupTime}
          />
        </div>
        {props.duration && (
          <>
            <div data-testid='dropoff-hour'>
              <FormLabel
                htmlFor='DropoffHour'
                label={t('form.time.to')}
                mandatory={missionMandatory.dropoffTime}
              />
              <PickerTime
                id='dropoffTime'
                onChange={(e) => {
                  props.updateMission('dropoffTime', e);
                }}
                value={dropoffTime}
              />
            </div>
            <div className='time__input__duration'>
              <FormLabel htmlFor='duration' label={t('form.time.duration')} />
              <input
                type='number'
                name='duration'
                data-testid='duration'
                id='duration'
                className='time__input__duration__number'
                min={1}
                onChange={(e) => {
                  const newTime = new Date(pickupTime || 0);
                  newTime.setHours(newTime.getHours() + parseInt(e.target.value));
                  props.updateMission('dropoffTime', newTime);
                }}
                value={duration}
              />
            </div>
          </>
        )}
      </div>
      <img className='time__input__help__icon' src={clock} alt='clock' />
      <span className='body-medium-small time__input__help'>{t('form.time.help')}</span>
    </div>
  );
};

export default Time;
