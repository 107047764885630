interface Props {
  id: string;
  testid?: string;
  className: string;
  text: string;
  pref?: string;
  suf?: string;
  handleClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
}

const Button: React.FC<Props> = (props) => {
  const sufIcon = props.suf ? <img src={props.suf} /> : null;
  const prefIcon = props.pref ? <img src={props.pref} /> : null;

  return (
    <button
      id={props.id}
      data-id={props.id}
      data-testid={props.testid ?? props.id}
      className={props.className + ' text-uppercase'}
      onClick={props.handleClick}
      type={props.type ?? 'button'}
    >
      <div className='content-button'>
        <div>{prefIcon}</div>
        <div>{props.text}</div>
        <div>{sufIcon}</div>
      </div>
    </button>
  );
};

export default Button;
