import ITransport, { getTransportName, getTransportTypeIcon, TransportType } from '../../types/transport.type';
import React, { useEffect, useState } from 'react';
import TransportToogleComponent from './TransportToggle.component';
import InputText from '../form/inputs/InputText.component';
import searchIcon from '../../assets/img/icon/search.svg';
import { t } from 'i18next';
import TransportDetailsFormComponent from './TransportDetailsForm.component';
import EventBus from '../../common/EventBus';
import { getAccessToken } from '../../utils/getAccessToken';
import AutocompletionService from '../../services/autocompletion/autocompletion.service';
import { useMsal } from '@azure/msal-react';

interface Props {
  date?: Date | undefined;
  arrival?: boolean | undefined;
  transport: ITransport | undefined;
  update: (value: ITransport | null) => void;
}

const TransportFormComponent: React.FC<Props> = (props) => {
  const { instance } = useMsal();
  const [error, setError] = useState<boolean>(false);
  const [transport, setTransport] = useState<ITransport>({} as ITransport);
  const [suffix, setSuffix] = useState<string | null>(null);

  useEffect(() => {
    if (transport.type === TransportType.PLANE) {
      setSuffix(searchIcon);
    } else {
      setSuffix(null);
    }
  }, [transport.type]);

  useEffect(() => {
    setTransport(props.transport ?? ({} as ITransport));
  }, [props.transport]);

  const getTransportDetails = async () => {
    if (props.transport?.reference && props.transport?.reference != '' && props.date) {
      try {
        const token = await getAccessToken(instance);
        const res = await AutocompletionService.getFlightInformation(
          token,
          props.date,
          props.transport.reference,
          props.arrival ?? true,
        );
        props.update({ ...transport, ...res } as ITransport);
        setError(false);
      } catch (e) {
        setError(true);
      } finally {
        EventBus.dispatch('notLoading');
      }
    }
  };
  return (
      <div className='transport-form' data-testid='transport-form'>
        <TransportToogleComponent
          type={transport.type}
          update={async function (e: string | null) {
            let transportEdited = {} as ITransport;
            if (e != null) {
              transportEdited = transport;
              transportEdited.type = Number(e);
              setError(false);
            }
            props.update(transportEdited);
          }}
        />
        {transport.type != undefined && (
          <>
            <InputText
              label='input-text-transport-reference'
              id='input-text-transport-reference'
              prefix={getTransportTypeIcon(transport.type)}
              class_name={`input-text-transport-reference${
                transport.type === TransportType.TRAIN ? '__train' : transport.type === TransportType.INFORMATION ? '__info' : ''
              }`}
              name='input-text-transport-reference'
              placeholder={t(`form.trip.transport.reference.placeholder.${getTransportName(transport.type)}`)}
              onChange={async function (e: React.ChangeEvent<HTMLInputElement>) {
                const transportEdited = transport;
                transportEdited.reference = e.target.value;
                props.update(transportEdited);
              }}
              value={transport.reference}
              suffix={suffix}
              onSuffixClick={getTransportDetails}
            />
            <TransportDetailsFormComponent
              transport={transport}
              date={props.date}
              error={error}
              update={props.update}
            />
          </>
        )}
      </div>
  );
};

export default TransportFormComponent;
