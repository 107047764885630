import React from 'react';
import Button from '../../button/Button.component';
interface Props {
  handleCancel?: () => void;
  handleRefuse?: () => void;
  handleApprove?: () => void;
  textApprove: string;
  textCancel: string;
}

const CancelButton: React.FC<Props> = (props) => {
  return (
    <div className='cancel-buttons'>
      <hr className='cancel-buttons__divider' />
      <div className='cancel-buttons__container'>
        <Button
          id='cancel-button'
          className='request-button-secondary cancel-buttons__cancel'
          text={props.textApprove}
          handleClick={props.handleApprove}
        />
        <Button
          id='confirm-cancel-button'
          className='request-button-primary cancel-buttons__confirm'
          text={props.textCancel}
          handleClick={props.handleRefuse}
        />
      </div>
    </div>
  );
};

export default CancelButton;
