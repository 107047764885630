import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import AuthService from '../../services/user/auth.service';

export default function Login() {
  const { instance } = useMsal();

  const handleLogin = () => {
    AuthService.login(instance);
  };

  useEffect(() => {
    handleLogin();
  }, []);

  return <></>;
}
