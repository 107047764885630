import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import React, { ChangeEvent, useEffect, useState } from 'react';
import InputText from '../form/inputs/InputText.component';
import IMission, { missionMandatory } from '../../types/mission.type';
import FormLabel from '../form/form-label/FormLabel.component';

interface Props {
  mission?: IMission;
  onChange: (e: string) => void;
}

const ProjectReference: React.FC<Props> = (props) => {
  const { t } = useTranslation('ns1', { i18n });

  const [inputValue, setInputValue] = useState<string>('');

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    props.onChange(inputValue);
  };

  useEffect(() => {
    if (props.mission) {
      setInputValue(props.mission.externalReference ?? '');
    }
  }, [props.mission]);

  return (
    <div id='project-reference' data-testid='project-reference' className='project-reference'>
      <h6>
        <FormLabel
          label={t('form.projectReference.title')}
          mandatory={missionMandatory.externalReference}
        />
      </h6>
      <InputText
        label=''
        id='input-project-reference'
        prefix=''
        class_name='text-passenger-name input-text__wide'
        name='project-reference'
        placeholder={t('form.projectReference.placeholder')}
        onChange={handleInputChange}
        value={inputValue}
      />
    </div>
  );
};

export default ProjectReference;
