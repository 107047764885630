import React from 'react';
import logoChabe from '../../assets/img/header/logo-chabe.svg';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/config';
import AccountMenu from '../block/account_menu/AccountMenu.component';
import LanguageSelector from '../language-selector/languageSelector.component';
import Notifications from '../notifications/Notifications.component';

const Header = () => {
  const { t } = useTranslation('ns1', { i18n });

  return (
    <div id='header' data-testid='header' className='header-chabe'>
      <div className='header-item d-flex justify-content-between'>
        <li id={'header-home'} className='header-home'>
          <img src={logoChabe} alt={t('accessibility.alt.logo-chabe')} />
        </li>
        {/* <li>
                    <span id="header-baseline" className="baseline">{headerBaseline}</span>
                </li> */}
        <li>
          <LanguageSelector />
        </li>
        <li>
          <Notifications />
        </li>
        <li>
          <AccountMenu />
        </li>
      </div>
    </div>
  );
};

export default Header;
