import i18n from 'i18next';

export enum MissionStatus {
  QuotationInProgress = 'quotationInProgress',
  QuotationAvailable = 'quotationAvailable',
  RideStarted = 'rideStarted',
  ChauffeurOnLocation = 'chauffeurOnLocation',
  PassengerOnBoard = 'passengerOnBoard',
  PassengerDroppedOff = 'passengerDroppedOff',
  Closed = 'closed',
  Canceled = 'canceled',
  Billed = 'billed',
  PendingProcessing = 'pendingProcessing',
  UpcomingBooking = 'upcomingBooking',
}

export default class MissionStatusEnum {
  public static getValueToString(status: MissionStatus) {
    switch (status) {
      case MissionStatus.QuotationInProgress:
        return i18n.t('components.booking.status.quotationInProgress');
      case MissionStatus.QuotationAvailable:
        return i18n.t('components.booking.status.quotationAvailable');
      case MissionStatus.RideStarted:
        return i18n.t('components.booking.status.rideStarted');
      case MissionStatus.ChauffeurOnLocation:
        return i18n.t('components.booking.status.chauffeurOnLocation');
      case MissionStatus.PassengerOnBoard:
        return i18n.t('components.booking.status.passengerOnBoard');
      case MissionStatus.PassengerDroppedOff:
        return i18n.t('components.booking.status.passengerDroppedOff');
      case MissionStatus.Closed:
        return i18n.t('components.booking.status.closed');
      case MissionStatus.Canceled:
        return i18n.t('components.booking.status.canceled');
      case MissionStatus.Billed:
        return i18n.t('components.booking.status.billed');
      case MissionStatus.PendingProcessing:
        return i18n.t('components.booking.status.pendingProcessing');
      case MissionStatus.UpcomingBooking:
        return i18n.t('components.booking.status.upcomingBooking');
    }
  }

  public static getMissionsStatusFromString(status: string) {
    return (Object.values(MissionStatus) as string[]).includes(status)
      ? status as MissionStatus
      : undefined;
  }

  public static getMissionsStatusFromWayniumStatus(wayniumStatus: number) {
    switch (wayniumStatus) {
      case 1:
        return MissionStatus.QuotationInProgress;
      case 17:
        return MissionStatus.QuotationInProgress;
      case 15:
        return MissionStatus.QuotationInProgress;
      case 2:
        return MissionStatus.QuotationAvailable;
      case 14:
        return MissionStatus.UpcomingBooking;
      case 16:
        return MissionStatus.UpcomingBooking;
      case 4:
        return MissionStatus.UpcomingBooking;
      case 11:
        return MissionStatus.RideStarted;
      case 8:
        return MissionStatus.PassengerOnBoard;
      case 9:
        return MissionStatus.Closed;
      case 19:
        return MissionStatus.Closed;
      case 13:
        return MissionStatus.Closed;
      case 21:
        return MissionStatus.Closed;
      case 7:
        return MissionStatus.Canceled;
      case 22:
        return MissionStatus.Billed;
    }
  }

  public static getBackgroundColor(status: MissionStatus) {
    switch (status) {
      case MissionStatus.QuotationInProgress:
        return '#E56000';
      case MissionStatus.QuotationAvailable:
        return '#FFC107';
      case MissionStatus.RideStarted:
        return '#4CAF50';
      case MissionStatus.PassengerOnBoard:
      case MissionStatus.ChauffeurOnLocation:
        return '#001C40';
      case MissionStatus.Closed:
      case MissionStatus.PassengerDroppedOff:
        return '#3568D4';
      case MissionStatus.Billed:
      case MissionStatus.Canceled:
        return '#60329B';
      case MissionStatus.PendingProcessing:
        return '#C5003E';
      case MissionStatus.UpcomingBooking:
        return 'rgba(132,255,0,0.39)';
    }
  }

  public static getColor(status: MissionStatus) {
    switch (status) {
      case MissionStatus.QuotationInProgress:
      case MissionStatus.PendingProcessing:
      case MissionStatus.Billed:
      case MissionStatus.PassengerOnBoard:
      case MissionStatus.ChauffeurOnLocation:
      case MissionStatus.PassengerDroppedOff:
      case MissionStatus.Closed:
      case MissionStatus.Canceled:
        return 'white';
      case MissionStatus.QuotationAvailable:
      case MissionStatus.RideStarted:
      case MissionStatus.UpcomingBooking:
        return '#001C40';
    }
  }

  public static getButtonStyleColor(status: MissionStatus) {
    return { backgroundColor: this.getBackgroundColor(status), color: this.getColor(status) };
  }
}
