import { TransportType } from '../../types/transport.type';
import React, { useEffect } from 'react';
import TransportIconComponent from './TransportIcon.component';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

interface Props {
  type: TransportType | null;
  update?: (value: any) => void;
}

const TransportToogleComponent: React.FC<Props> = (props) => {
  const [transportType, setTransportType] = React.useState<TransportType | null>(null);

  const handleTransportType = (
    event: React.MouseEvent<HTMLElement>,
    newTransportType: TransportType | null,
  ) => {
    if (props.update) {
      props.update(newTransportType);
    }
  };

  useEffect(() => {
    setTransportType(props.type);
  }, [props.type]);

  return (
      <div className='transport-toogle' data-testid='transport-toogle'>
        <ToggleButtonGroup
          value={transportType}
          exclusive
          onChange={handleTransportType}
          aria-label='Transport type'
        >
          <ToggleButton
            value={TransportType.PLANE}
            data-testid={'transport-type-' + TransportType.PLANE}
            aria-label={'transport-type-' + TransportType.PLANE}
          >
            <TransportIconComponent type={TransportType.PLANE} />
          </ToggleButton>

          <ToggleButton
            value={TransportType.TRAIN}
            data-testid={'transport-type-' + TransportType.TRAIN}
            aria-label={'transport-type-' + TransportType.TRAIN}
          >
            <TransportIconComponent type={TransportType.TRAIN} />
          </ToggleButton>

          <ToggleButton
            value={TransportType.INFORMATION}
            data-testid={'transport-type-' + TransportType.INFORMATION}
            aria-label={'transport-type-' + TransportType.INFORMATION}
          >
            <TransportIconComponent type={TransportType.INFORMATION} />
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
  );
};

export default TransportToogleComponent;
