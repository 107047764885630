import axios from 'axios';
import EventBus from '../../common/EventBus';
import MissionStatusEnum from '../../types/mission-status.enum';
import INotification from '../../types/notification.type';

const API_GET_NOTIFICATIONS = `${process.env.REACT_APP_API_MISSIONS_URL}/events/user`;
const API_UPDATE_NOTIFICATIONS = `${process.env.REACT_APP_API_MISSIONS_URL}/events/markRead/`;

export class NotificationsService {
  public static async getNotifications(token: string): Promise<any> {
    const headers = {
      authorization: `Bearer ${token}`,
    };
    try {
      const res = await axios.get(API_GET_NOTIFICATIONS, { headers });
      return res.data.map((notification: any) => {
        notification.mission.pickupDate = new Date(notification.mission.pickupDate);
        notification.mission.dropoffDate = new Date(notification.mission.dropoffDate);
        notification.mission.pickupTime = new Date(notification.mission.pickupTime);
        notification.mission.status = MissionStatusEnum.getMissionsStatusFromString(notification.mission.status);
        return notification;
      });
    } catch (error: any) {
      const statusCode = error.response ? error.response.status : null;
      if (statusCode === 401) {
        EventBus.dispatch('logout');
      }
      return [];
    }
  }

  public static async markRead(token: string, id: number): Promise<any> {
    const headers = {
      authorization: `Bearer ${token}`,
    };
    try {
      const res = await axios.put(API_UPDATE_NOTIFICATIONS + id, {}, { headers });
      return res.data;
    } catch (error: any) {
      const statusCode = error.response ? error.response.status : null;
      if (statusCode === 401) {
        EventBus.dispatch('logout');
      }
      return [];
    }
  }
}
