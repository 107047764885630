import { MissionStatus } from './mission-status.enum';
import IPassenger from './passenger.type';
import IService from './service.type';
import IStep from './step.type';
import IVehicle from './vehicle.type';

export default interface IMissionLight {
  [key: string]: any;
  id?: string;
  folderId?: string;
  missionNumber?: string;
  accountName: string;
  service: IService;
  status: MissionStatus;
  passengers: IPassenger[];
  pickupDate: Date;
  pickupTime: Date;
  dropoffTime?: Date;
  steps: IStep[];
  vehicle: IVehicle;
  description: string;
  externalReference: string;
  dateCanceled?: Date;
  price?: string | null;
}

export const missionLightMandatory = {
  id: false,
  folderId: false,
  missionNumber: false,
  accountName: false,
  service: true,
  status: false,
};
