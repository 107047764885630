import axios from 'axios';
import { UserType } from '../../types/user.type';

class UserService {
  public static async getMe(accessToken: string) {
    
    return await axios
      .get<UserType>(`${process.env.REACT_APP_API_HABILITATIONS_URL}/v1/auth/me/adb2c`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

export default UserService;
