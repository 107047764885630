import plane from '../assets/img/icon/plane-takeoff.svg';
import planeWhite from '../assets/img/icon/plane-takeoff-white.svg';
import train from '../assets/img/icon/train.svg';
import trainWhite from '../assets/img/icon/train-white.svg';
import information from '../assets/img/icon/status.svg';
import informationWhite from '../assets/img/icon/status-white.svg';

export enum TransportType {
  PLANE,
  TRAIN,
  INFORMATION,
}

export enum TransportDirection {
  ARRIVAL,
  DEPARTURE,
}

export default interface ITransport {
  reference: string;
  type: TransportType;
  direction?: TransportDirection;
  from?: string;
  to?: string;
  hour?: string;
  terminal?: string;
}

const transportTypeIcons: Record<TransportType, string> = {
  [TransportType.INFORMATION]: information,
  [TransportType.PLANE]: plane,
  [TransportType.TRAIN]: train,
};

const transportTypeIconsWhite: Record<TransportType, string> = {
  [TransportType.INFORMATION]: informationWhite,
  [TransportType.PLANE]: planeWhite,
  [TransportType.TRAIN]: trainWhite,
};

export function getTransportTypeIconWhite(transportType: TransportType): string {
  return transportTypeIconsWhite[transportType];
}

export function getTransportTypeIcon(transportType: TransportType): string {
  return transportTypeIcons[transportType];
}

export function getTransportName(transportType: TransportType): string {
  switch (transportType) {
    case TransportType.INFORMATION:
      return 'information';
    case TransportType.TRAIN:
      return 'train';
    case TransportType.PLANE:
      return 'plane';
    default:
      return '';
  }
}
