import React, { useEffect, useState } from 'react';
import SelectVehicle from '../../components/select-vehicle/SelectVehicle.component';
import { useTranslation } from 'react-i18next';
import Trip from '../../components/trip/Trip.component';
import Time from '../../components/block/time/Time.component';
import MoreInformation from '../../components/block/more_information/MoreInformation.component';
import ActionRequest from '../../components/block/action_request/ActionRequest.component';
import i18n from 'i18next';
import Passenger from '../../components/passenger/Passenger.component';
import Toast from '../../components/toast/Toast.component';
import Modal from '../../components/modal/Modal.component';
import Confirmation from '../../components/block/confirmation/Confirmation.component';
import { useNavigate } from 'react-router-dom';
import IMission, { missionMandatory } from '../../types/mission.type';
import IMissionErrors from '../../types/mission.error.type';
import Checkbox from '../form/checkbox/checkbox.component';
import ServiceType from '../service-type/service-type.component';
import ProjectReference from '../project-reference/projectReference.component';
import { errorsToMessage } from '../../utils/errorsUtils';
import EventBus from '../../common/EventBus';
import { getAccessToken } from '../../utils/getAccessToken';
import { useMsal } from '@azure/msal-react';
import { BookingsTabs } from '../../types/bookings-tab.enum';
import usePermissions from '../../config/usePermission';
import getDispatch from '../../config/userDispatch';
import { PERMISSIONS } from '../../types/permissions.enum';
import Forbidden from '../../pages/forbidden/Forbidden';
import TimezoneService from '../../services/timezone/timezone.service';
import { KindPage } from '../../types/kind-page.enum';

type Props = {
  state_modal: boolean;
  kind_of_page: KindPage;
  service: any;
  missionId?: string | null;
};

const Mission: React.FC<Props> = (props) => {
  // == Init ===============================================================
  const navigate = useNavigate();
  const { instance } = useMsal();

  const { t } = useTranslation('ns1', { i18n });
  const [mission, setMission] = useState({} as IMission);
  const [forbidden, setForbidden] = useState(false);
  const [errors, _setErrors] = useState<IMissionErrors>({} as IMissionErrors);

  const errorsRef = React.useRef(errors);
  const setErrors = (data: IMissionErrors) => {
    errorsRef.current = data;
    _setErrors(data);
  };

  const [errorsMessage, setErrorsMessage] = useState<string>('');
  const [duration, setDuration] = useState(false);
  const [minSteps, setMinSteps] = useState(1);
  const dispatch = getDispatch();
  const hasPermissionBookingRead = usePermissions(PERMISSIONS['Workflow.Missions.PostRequest']);
  const hasPermissionMissionsRequestDescription = usePermissions(PERMISSIONS['Missions.RequestDescription'])
  const hasPermissionMissionsVIPMeetGreat = usePermissions(PERMISSIONS['Missions.VIPMeetGreat'])
  const hasPermissionQuotesRequestDescription = usePermissions(PERMISSIONS['Quotes.RequestDescription'])
  const hasPermissionQuotesVIPMeetGreat = usePermissions(PERMISSIONS['Quotes.VIPMeetGreat'])
  const isPhoneNumberMandatory = usePermissions(PERMISSIONS['Missions.PassengerPhoneMandatory'])

  // == Functions ===============================================================

  const hasPermissionRequestDescription = () => {
    return (props.kind_of_page === KindPage.Estimate && hasPermissionQuotesRequestDescription) ||
      (props.kind_of_page === KindPage.Reservation && hasPermissionMissionsRequestDescription)
  }
  const hasPermissionVIPMeetGreat = () => {
    return (props.kind_of_page === KindPage.Estimate && hasPermissionQuotesVIPMeetGreat) ||
      (props.kind_of_page === KindPage.Reservation && hasPermissionMissionsVIPMeetGreat)
  }
  const HANDLE_CHANGE = (property: string, value: any) => {
    setMission((prevState) => ({ ...prevState, [property]: value }));
  };

  const HANDLE_VALIDATE = async () => {
    const validateMandatory = (key: string, value: any) => {
      return value && (!mission.hasOwnProperty(key) || !mission[key]) ? { [key]: 'mandatory' } : {};
    };

    const validatePassengers = (key: string, value: any) => {
      if (value && key === 'passengers' && mission.passengers) {
        if (!mission.passengers[0].lastname) {
          return { [key + '_name']: 'mandatory' };
        }
        if (!mission.passengers[0].phone && isPhoneNumberMandatory) {
          return { [key + '_phone']: 'mandatory' };
        }
      }
      return {};
    };

    const validateSteps = (key: string, value: any) => {
      if (value && key === 'steps' && mission.steps) {
        const stepsEmpty = mission.steps.filter((step) => !step.address.value);
        return stepsEmpty.length > 0 ? { [key]: 'not-empty' } : {};
      }
      return {};
    };

    const validatePickupTime = (key: string, value: any) => {
      if (value && key === 'pickupTime' && mission.pickupTime && mission.pickupDate && mission.steps) {
        const dateMission = new Date(new Date(mission.pickupDate).getTime() + new Date(mission.pickupDate).getTimezoneOffset() * 60000);
        const time = new Date(new Date(mission.pickupTime).getTime() + new Date(mission.pickupTime).getTimezoneOffset() * 60000)
        dateMission.setHours(time.getHours());
        dateMission.setMinutes(time.getMinutes());

        const timezone = mission.steps.find((step) => step.order === 0)?.address?.timezone;
        let dateMini = new Date();
        if(timezone != undefined){
          try {
            dateMini = TimezoneService.convertTZ(dateMini, timezone)
          } catch {
            return { [key]: `too-late.${dispatch}` };
          }

        }
        dateMini.setHours(dateMini.getHours() + 12);
        dateMini.setMinutes(new Date().getMinutes());

        return dateMission < dateMini ? { [key]: `too-late.${dispatch}` } : {};
      }
      return {};
    };

    const validateDropoffTime = (key: string, value: any) => {
      if (key === 'dropoffTime' && mission.service && (mission.service.type.toLowerCase() === 'transport') && !mission.dropoffTime) {
        return { [key]: 'mandatory' };
      }
      return {};
    };

    const missionMandatoryEntries = Object.entries(missionMandatory);
    const missionErrors = missionMandatoryEntries.reduce((acc, [key, value]) => {
      return {
        ...acc,
        ...validateMandatory(key, value),
        ...validateSteps(key, value),
        ...validatePassengers(key, value),
        ...validatePickupTime(key, value),
        ...validateDropoffTime(key, value),
      };
    }, {} as IMissionErrors);

    await HANDLE_SET_ERRORS(missionErrors);

    return Object.keys(missionErrors).length === 0;
  };

  const HANDLE_SET_ERRORS = async (missionErrors: IMissionErrors) => {
    setErrorsMessage('');
    setErrors(missionErrors);
  };

  const HANDLE_SET_ERRORS_MESSAGE = async () => {
    setErrorsMessage(errorsToMessage(errorsRef.current));
  };

  const HANDLE_SAVE = async () => {
    try {
      EventBus.dispatch('modalIsClose');
      EventBus.dispatch('loading');

      if (!(await HANDLE_VALIDATE())) return;

      const missionToSend = JSON.parse(JSON.stringify(mission));

      if (missionToSend.greeting === true) {
        const missionWithoutId = { ...missionToSend };
        if (missionWithoutId.id) {
          delete missionWithoutId.id;
        }
        if (missionWithoutId.folderId) {
          delete missionWithoutId.folderId;
        }
        if (missionWithoutId.missionNumber) {
          delete missionWithoutId.missionNumber;
        }
        missionToSend.relatedMissions = [{ ...missionWithoutId, service: { type: 'accueil' } }];
        delete missionToSend.greeting;
      }

      const token = await getAccessToken(instance);

      const promise = props.missionId
        ? props.service.update(token, props.missionId, missionToSend)
        : props.service.create(token, missionToSend);

      const response = await promise;

      EventBus.dispatch('modalIsClose');
      EventBus.dispatch('notLoading');

      const action = props.missionId ? 'update' : 'create';
      const label = `success.${props.kind_of_page}.${action}`;

      let bookingActiveTab = BookingsTabs.UpcomingBookings;
      if (props.kind_of_page === KindPage.Estimate) {
        bookingActiveTab = BookingsTabs.QuotationsRequests;
      } else if (hasPermissionBookingRead) {
        bookingActiveTab = BookingsTabs.BookingRequests;
      }

      sessionStorage.setItem('success', label);
      sessionStorage.setItem('bookingActiveTab', String(bookingActiveTab));
      navigate('/dashboard');
    } catch (error) {
      await handleSaveError(error);
    }
  };

  const handleSaveError = async (error: any) => {
    const errors = error.errors ? error.errors : { technical: error.message ?? error.error };
    await HANDLE_SET_ERRORS(errors);
    EventBus.dispatch('notLoading');
    EventBus.dispatch('modalIsClose');
  };

  useEffect(() => {
    if (mission?.service?.type) {
      if (mission.service.type.toLowerCase() === 'transfert') {
        setDuration(false);
        setMinSteps(2);
      } else if (mission.service.type.toLowerCase() === 'transport') {
        setDuration(true);
        setMinSteps(1);
      }
    }
  }, [mission]);

  // == Hooks ===============================================================

  useEffect(() => {
    const fetchData = async () => {
      if (props.missionId) {
        try {
          EventBus.dispatch('loading');
          const token = await getAccessToken(instance);
          const res = await props.service.getMission(token, props.missionId);
          setMission(res);
        } catch (error) {
          setForbidden(true);
        } finally {
          EventBus.dispatch('notLoading');
        }
      } else {
        setMission({} as IMission);
      }
    };

    fetchData();
  }, [props.missionId]);

  useEffect(() => {
    i18n.on('languageChanged', HANDLE_SET_ERRORS_MESSAGE);

    return () => {
      i18n.off('languageChanged', HANDLE_SET_ERRORS_MESSAGE);
    };
  }, [i18n]);

  useEffect(() => {
    HANDLE_SET_ERRORS_MESSAGE();
  }, [errors]);

  // == Render ===============================================================

  return (
    <div>
      {forbidden && <Forbidden />}
      {!forbidden && (
        <form
          data-testid='mission-form'
          className='mission-form'
          onSubmit={async (e) => {
            e.preventDefault();
            await HANDLE_SAVE();
          }}
        >
          <h5>
            {props.missionId
              ? t(`${props.kind_of_page}.title-update`) + ` ${mission.folderId || ''}-${mission.missionNumber}`
              : t(`${props.kind_of_page}.title`)}
          </h5>
          <ServiceType service={mission?.service} onChange={HANDLE_CHANGE} />
          <hr className='mission-form__divider' />
          <Time updateMission={HANDLE_CHANGE} mission={mission} duration={duration} />
          <hr className='mission-form__divider' />
          <Passenger updateMission={HANDLE_CHANGE} mission={mission} />
          <hr className='mission-form__divider' />
          <Trip
            kind={props.kind_of_page}
            class_name='trip_input'
            updateMission={HANDLE_CHANGE}
            mission={mission}
            minSteps={minSteps}
          />

          <hr className='mission-form__divider' />
          <SelectVehicle updateMission={HANDLE_CHANGE} mission={mission} />
          <hr className='mission-form__divider' />
          <ProjectReference
            mission={mission}
            onChange={(value) => {
              HANDLE_CHANGE('externalReference', value);
            }}
          />
          {hasPermissionRequestDescription() && (
            <div>
              <hr className="mission-form__divider" />
              <MoreInformation
                onChange={(value) => {
                  HANDLE_CHANGE('description', value.target.value);
                }}
                id="estimate_textarea"
                class_name=""
                name="details"
                label={t(`${props.kind_of_page}.labels.textarea`)}
                mission={mission}
              />
            </div>
          )}
          {hasPermissionVIPMeetGreat() && (
            <div>
              <hr className="mission-form__divider" />
              <h6>{t('form.greeting.title')}</h6>
              <Checkbox
                className="checkbox__greeting"
                data-testid="greeting"
                id="greeting"
                label={t('form.greeting.text')}
                onChange={(value) => {
                  HANDLE_CHANGE('greeting', value);
                }}
              />
            </div>
          )}
          {Object.keys(errors).length > 0 && errorsMessage != '' && (
            <Toast kind="error" message={errorsMessage} disabledIcon={true} />
          )}
          <ActionRequest
            validate={HANDLE_VALIDATE}
            text_confirm={t(`form.${props.kind_of_page}.send_request`)}
          />
          <Modal
            state={props.state_modal}
            handleClose={() => EventBus.dispatch('modalIsClose')}>
            <Confirmation mission={mission} kindOfPage={props.kind_of_page}/>
          </Modal>
        </form>
      )}
    </div>
  );
};

export default Mission;
