import React, { useEffect, useState } from 'react';
import NewRequest from '../../components/new-request/NewRequest.component';
import Toast from '../../components/toast/Toast.component';
import i18n, { t } from 'i18next';
import BookingBlock from '../../components/block/booking/Booking.block';
import Button from '../../components/button/Button.component';
import usePermissions from '../../config/usePermission';
import { PERMISSIONS } from '../../types/permissions.enum';
import EventBus from '../../common/EventBus';

type Props = {
  state_modal: boolean;
};

const Dashboard: React.FC<Props> = (props) => {
  const [successMessageKey, setSuccessMessageKey] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessageKey, setErrorMessageKey] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const hasPermissionArrivalBoardRead = usePermissions(PERMISSIONS['ArrivalsBoard.Read']);

  const todaysarrivals = (): void => {
    window.open(`${process.env.REACT_APP_TODAYSARRIVALSURL}`, '_blank');
  };

  const HANDLE_SET_ERRORS_MESSAGE = (): void => {
    if (successMessageKey) {
      const translatedSuccess = t(successMessageKey);

      if (typeof translatedSuccess === 'string') {
        setSuccessMessage(translatedSuccess);
      } else {
        setSuccessMessageKey(null);
      }
    } else {
      setSuccessMessageKey(null);
    }
    if (errorMessageKey) {
      const translatedError = t(errorMessageKey);
      if (typeof translatedError === 'string') {
        setErrorMessage(translatedError);
      } else {
        setErrorMessageKey(null);
      }
    } else {
      setErrorMessageKey(null);
    }
  };

  const updateMessage = () => {
    let key = sessionStorage.getItem('success');
    setSuccessMessageKey(key);
    sessionStorage.removeItem('success');
    key = sessionStorage.getItem('error');
    setErrorMessageKey(key);
    sessionStorage.removeItem('error');
  };

  useEffect(() => {
    HANDLE_SET_ERRORS_MESSAGE();
  }, [successMessageKey, errorMessageKey]);

  useEffect(() => {
    updateMessage();
    EventBus.on('updateToast', updateMessage);
  }, []);

  useEffect(() => {
    i18n.on('languageChanged', HANDLE_SET_ERRORS_MESSAGE);

    return () => {
      i18n.off('languageChanged', HANDLE_SET_ERRORS_MESSAGE);
    };
  }, [i18n]);

  return (
    <div id='dashboard' data-testid='dashboard' className='dashboard-page'>
      <div className='d-flex justify-content-between'>
        <NewRequest />
        {hasPermissionArrivalBoardRead && (
          <Button
            id='arrivals'
            className='request-button-secondary request-button-secondary--border-blue'
            handleClick={todaysarrivals}
            text={t('todaysArrivals')}
          />
        )}
      </div>
      {successMessageKey && successMessage && <Toast kind='success' message={successMessage} />}
      {errorMessageKey && errorMessage && <Toast kind='error' message={errorMessage} />}
      <h2>{t('pages.dashboard.bookings-title')}</h2>
      <BookingBlock />
    </div>
  );
};

export default Dashboard;
