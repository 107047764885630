import React from 'react';

type Props = {
  title?: string;
  content?: string;
};

const StaticPage: React.FC<Props> = (props) => {
  return (
    <div id='static-page' data-testid='static-page' className='static-page-content'>
      <h5>{props.title}</h5>
      <div dangerouslySetInnerHTML={{ __html: props.content ?? '' }} />
    </div>
  );
};

export default StaticPage;
