import React, { useEffect, useState } from 'react';
import IPassenger from '../../types/passenger.type';
import { Avatar } from '@mui/material';
import colors from '../../scss/utils/_variables.module.scss';

interface Props {
  passenger: IPassenger;
  bg_color?: string;
  active?: boolean;
}

const PassengerItem: React.FC<Props> = (props) => {
  const [passenger, setPassenger] = useState({} as IPassenger);

  // == Hooks ===============================================================

  useEffect(() => {
    setPassenger(props.passenger);
  }, [props.passenger]);

  return (
    <div
      className={
        'passenger-item ' + props.bg_color + (props.active ? ' passenger-item__active' : '')
      }
    >
      <Avatar
        sx={{
          textTransform: 'uppercase',
          bgcolor: colors?.primaryColor,
          color: colors?.lightGrey,
          fontFamily: 'EuclidCircularA-Semibold',
          fontSize: '14px',
        }}
      >
        {passenger && passenger.firstname?.charAt(0) + passenger.lastname?.charAt(0)}
      </Avatar>
      <div className='passenger-item__infos'>
        <span className='body-medium-small passenger-item__infos__name'>
          {passenger.lastname} {passenger.firstname}
        </span>
        <span className='body-extra-small passenger-item__infos__email'>{passenger.email}</span>
      </div>
    </div>
  );
};

export default PassengerItem;
