import React from 'react';
import Button from '../button/Button.component';
import { useNavigate } from 'react-router-dom';
import usePermissions from '../../config/usePermission';
import { PERMISSIONS } from '../../types/permissions.enum';
import { t } from 'i18next';

const NewRequest = () => {
  const navigate = useNavigate();
  const HandleClick = () => navigate('/reservation');
  const HandleQuotationClick = () => navigate('/estimate');
  const hasPermissionQuotesCreate = usePermissions(PERMISSIONS['Quotes.Create']);

  return (
    <div className='new-request'>
      <div className='nr-elements d-flex'>
        <div>
          <Button
            id='request-button'
            data-testid='request-button'
            className='request-button-primary'
            text={t('Request')}
            handleClick={HandleClick}
          />
        </div>
        {hasPermissionQuotesCreate && (
          <div className='mx-5'>
            <Button
              id='quotation-button'
              data-testid='quotation-button'
              className='request-button-secondary request-button-secondary--border-blue'
              text={t('Quotation')}
              handleClick={HandleQuotationClick}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default NewRequest;
