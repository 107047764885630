import IMissionLight, { missionLightMandatory } from './mission-light.type';
import IDriver from './driver.type';

export default interface IMission extends IMissionLight {
  relatedMissions?: IMissionLight[];
  driver?: IDriver;
  greeting?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;
  type?: string;
}

export const missionMandatory = {
  ...missionLightMandatory,
  passengers: true,
  pickupDate: true,
  pickupTime: true,
  dropoffTime: false,
  steps: true,
  vehicle: true,
  description: false,
  externalReference: false,
  relatedMissions: false,
  driver: false,
  price: false,
};
