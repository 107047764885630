import React from 'react';
import QuoteService from '../../services/quotes/quote.service';
import { useLocation } from 'react-router-dom';
import Mission from '../../components/mission/Mission.component';
import { KindPage } from '../../types/kind-page.enum';

type Props = {
  state_modal: boolean;
};

const Estimate: React.FC<Props> = (props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const missionId = searchParams.get('missionId');

  return (
    <Mission
      state_modal={props.state_modal}
      kind_of_page={KindPage.Estimate}
      missionId={missionId}
      service={QuoteService}
    />
  );
};

export default Estimate;
