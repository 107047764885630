import ITransport, { TransportType } from '../../types/transport.type';
import clock from '../../assets/img/icon/clock.svg';
import React from 'react';
import TransportIconComponent from './TransportIcon.component';
import { t } from 'i18next';

interface Props {
  transport: ITransport;
}

const TransportViewComponent: React.FC<Props> = (props) => {
  return (
    <>
      <div className='transport-view' data-testid='transport-view'>
        <span className='reference'>
          <TransportIconComponent type={props.transport.type} />
          {props.transport.reference}
        </span>
        {(props.transport.from || props.transport.to) && (
          <span className='informations'>
            {props.transport.from} {props.transport.to}
          </span>
        )}
        {props.transport.terminal && (
          <span className='terminal'>
            {props.transport.type === TransportType.TRAIN
              ? t('components.booking.row.track')
              : t('components.booking.row.terminal')}{' '}
            : {props.transport.terminal}
          </span>
        )}
        {props.transport.hour && (
          <span className='hour'>
            <img src={clock} alt='clock' />
            {props.transport.hour}
          </span>
        )}
      </div>
    </>
  );
};

export default TransportViewComponent;
