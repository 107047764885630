import { getTransportTypeIconWhite, TransportType } from '../../types/transport.type';
import React, { useEffect, useState } from 'react';

interface Props {
  type: TransportType;
}

const TransportIconComponent: React.FC<Props> = (props) => {
  const [icon, setIcon] = useState<string | undefined>(undefined);

  useEffect(() => {
    setIcon(getTransportTypeIconWhite(props.type));
  }, [props.type]);

  return (
    <>
      <img
        src={icon}
        alt='transport-icon'
        data-testid='transport-icon'
        className='transport-icon'
      />
    </>
  );
};

export default TransportIconComponent;
