import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import notificationIcon from '../../assets/img/icon/notification.svg';
import NotificationsItem from './NotificationsItem.component';
import { useMsal } from '@azure/msal-react';
import { getAccessToken } from '../../utils/getAccessToken';
import AuthService from '../../services/user/auth.service';
import { NotificationsService } from '../../services/notifications/notifications.service';
import INotification from '../../types/notification.type';
import emptyList from '../../assets/img/icon/empty-list.svg';

const Notifications: React.FC = () => {
  const { t } = useTranslation('ns1', { i18n });
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const { instance } = useMsal();
  const [unreadNotifications, setUnreadNotifications] = useState<number>(0);

  const getNotifications = async () => {
    getAccessToken(instance).then((token) => {
      AuthService.test(token);
      NotificationsService.getNotifications(token).then((results: INotification[]) => {
        setNotifications(results);
      });
    });
  };

  const readNotifications = async () => {
    getAccessToken(instance).then((token) => {
      AuthService.test(token);
      notifications.forEach((notification) => {
        NotificationsService.markRead(token, notification.id);
      });
    });
  };

  const handleOpen = () => {
    setOpen(!open);
    readNotifications();
    setUnreadNotifications(0);
  };

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    let unreadCount = 0;
    if (notifications) {
      notifications.forEach((result: INotification) => {
        if (!result.isRead) {
          unreadCount++;
        }
      });
    }
    setUnreadNotifications(unreadCount);
  }, [notifications]);

  return (
    <div className='notifications' data-testid='notifications'>
      <div
        className='notifications__icon'
        id='notifications-icon-button'
        data-testid='notifications-icon-button'
        onClick={handleOpen}
        tabIndex={0}
        onBlur={() => setOpen(false)}
      >
        <img src={notificationIcon} alt='bell' />
        {unreadNotifications > 0 && (
          <div className='notifications__icon__counter'>{unreadNotifications}</div>
        )}
      </div>
      {open && (
        <div className='notifications__content' data-testid='notifications-content'>
          <div>
            <div className='notifications__triangle'></div>
          </div>
          <div className='notifications__content__header'>
            <div>
              <img src={notificationIcon} alt='bell' />
              {unreadNotifications > 0 && (
                <div className='notifications__icon__counter notifications__icon__counter__secondary'>
                  {unreadNotifications}
                </div>
              )}
            </div>
            <span className='body-normal-small'>NOTIFICATIONS</span>
          </div>
          <div className='notifications__content__body'>
            {notifications &&
              notifications.map((item, index) => (
                <NotificationsItem key={item.id} notification={item.mission} index={index} />
              ))}
            {notifications && notifications.length === 0 && (
              <div className='notifications__content__body__empty'>
                <img src={emptyList} alt='empty-list' width={75} />
                <span>{t('components.notifications.empty.title')}</span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Notifications;
