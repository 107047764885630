import React from 'react';

interface ArticleProps {
  sufIcon?: string;
  title?: string;
  value: number | undefined;
  light: boolean | undefined;
}

const ArticleView: React.FC<ArticleProps> = (props) => {
  const sufIcon = props.sufIcon ? <img src={props.sufIcon} alt='article-icon' /> : null;

  return (
    <div className='article-view'>
      <div className='value'>{props.value}</div>
      {sufIcon}
      {!props.light && <div className='title'>{props.title}</div>}
    </div>
  );
};

export default ArticleView;
