import React, { useState } from 'react';
import { t } from 'i18next';
import ValidationButton from '../validation_button/ValidationButton.component';
import IMission from '../../../types/mission.type';
import MissionViewComponent from '../../mission/MissionView.component';

interface Props {
  quote: IMission;
  id: number;
  handleCancel?: (command_id: number) => void;
  handleRefuse?: (command_id: number, mission_id: number) => void;
  handleApprove?: (command_id: number, mission_id: number) => void;
}

const ValidationEstimate: React.FC<Props> = (props) => {
  const [displayPrice, setDisplayPrice] = useState(false);

  return (
    <div className='validation' data-testid='validation-estimate'>
      <h5 className='validation__title'>{t('form.action.quotation_is_ready')}</h5>
      <div className='validation__info'>
        <p className='validation__info-title'>{t('form.quotes.your_request')}</p>
        <MissionViewComponent mission={props.quote} />
        <hr className='validation__info-divider'></hr>
        <p className='validation__info-text body-small'>
          <strong>{t('form.quotes.approve')}</strong>
        </p>
        {displayPrice && (
          <p className='validation__info-text body-small'>
            {t('form.quotes.estimation_request')}
            <strong>{props.quote.price ? props.quote.price + '€' : ''}</strong>
          </p>
        )}
      </div>
      <ValidationButton
        handleCancel={() => {
          props.handleCancel && props.handleCancel(props.id);
        }}
        handleRefuse={() => {
          props.handleRefuse && props.handleRefuse(props.id, parseInt(props.quote.id ?? ''));
        }}
        handleApprove={() => {
          props.handleApprove && props.handleApprove(props.id, parseInt(props.quote.id ?? ''));
        }}
      />
    </div>
  );
};

export default ValidationEstimate;
