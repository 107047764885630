import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import IPassenger from '../../types/passenger.type';
import InputText from '../form/inputs/InputText.component';
import UserLogo from '../../assets/images/userInputLogo.svg';
import UserAddIcon from '../../assets/img/icon/user-add.svg';
import UserEditIcon from '../../assets/img/icon/user-edit.svg';
import EditIcon from '../../assets/img/icon/edit.svg';
import PassengerItem from '../passenger-item/PassengerItem.component';
import FormLabel from '../form/form-label/FormLabel.component';
import usePermissions from '../../config/usePermission';
import { PERMISSIONS } from '../../types/permissions.enum';

interface Props {
  passenger: IPassenger;
  updatePassager: (property: string, value: any) => void;
  setPassengerFromInput: (value: any) => void;
  setPassengersAutocompletion: (value: any) => void;
  handleGetPassenger: (value: string) => void;
  handleSidebarPassengerFormOpen: () => void;
  handle_change: (value: IPassenger) => void;
  handlePassengerDetailsSidebar: () => void;
  passengerLabel: string;
  passengersAutocompletion: IPassenger[] | undefined;
  isInSidebar: boolean;
}

const PassengerCredentialsComponent: React.FC<Props> = (props) => {
  const { t } = useTranslation('ns1', { i18n });
  const isPhoneNumberMandatory = usePermissions(PERMISSIONS['Missions.PassengerPhoneMandatory'])

  return (
    <>
      <div className={`input-passenger-details__field ${props.isInSidebar ? '' : 'input-passenger-details-no-sidebar'}`}>
        <InputText
          label='input-passenger'
          id='input-passenger'
          prefix={UserLogo}
          class_name='text-passenger-name input-text__wide'
          name='passengers'
          placeholder={t('form.passenger.placeholder')}
          onChange={async function (e: React.ChangeEvent<HTMLInputElement>) {
            props.setPassengerFromInput(e.target.value);
            props.handleGetPassenger(e.target.value);
          }}
          value={props.passengerLabel}
          onBlur={async function (e: React.ChangeEvent<HTMLInputElement>) {
            setTimeout(function () {
              props.setPassengersAutocompletion(undefined);
            }, 300);
          }}
          suffix={(!props.passenger || !props.passenger.id) ? UserAddIcon : UserEditIcon}
          onSuffixClick={props.handleSidebarPassengerFormOpen}
          readonly={!props.isInSidebar}
        />
        {!props.isInSidebar && 
          <img
            className='icon__clickable'
            data-testid='edit-passenger'
            alt='logo'
            src={EditIcon}
            onClick={() => {
              props.handlePassengerDetailsSidebar()
            }}
          />
        }
        {props.passengersAutocompletion && (
          <div className={'passenger__dropdown'}>
            {Object.entries(props.passengersAutocompletion).map(
              ([key, value]: [string, IPassenger], index) => (
                <div
                  className={
                    index % 2 == 0 ? 'passenger__item' : 'passenger__item item-bg-dark'
                  }
                  key={value.id}
                  onClick={() => {
                    props.handle_change(value);
                  }}
                >
                  <PassengerItem passenger={value} />
                </div>
              ),
            )}
          </div>
        )}
      </div>

      <div className='passenger-credentials'>
        <div className='field'>
          <div className='passenger-phone-title'>
            <div className='title'>
              <FormLabel label={t(`form.passengerCreate.phone`)}  mandatory={isPhoneNumberMandatory} />
            </div>
          </div>
          <InputText
            label=''
            id='phone-passenger'
            data-testid='phone-passenger'
            prefix=''
            class_name='text-passenger-name input-text__wide'
            name='passengers'
            placeholder={t('form.passengerCreate.placeholderPhone')}
            onChange={(e) => {
              if (!props.passenger.id) {
                props.updatePassager('phone', e.target.value);
              }
            }}
            value={props.passenger.phone}
            suffix={props.passenger.id ? UserEditIcon : ''}
            onSuffixClick={() => {
              if(props.passenger.id) {
                props.handleSidebarPassengerFormOpen()
              }
            }}
            readonly={!props.isInSidebar || !!props.passenger.id}
          />
        </div>
      </div>
      </>
  )

}

export default PassengerCredentialsComponent;