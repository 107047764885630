import axios from 'axios';

const API_URL_PASSENGERS = `${process.env.REACT_APP_API_PASSENGERS_URL}`;
const API_URL_MISSIONS = `${process.env.REACT_APP_API_MISSIONS_URL}`;

export class AutocompletionService {
  public static async getAddress(token: string, query: string, id_passenger?: number) {
    return await axios
      .get(`${API_URL_PASSENGERS}/address/autocomplete`, {
        params: {
          id: id_passenger,
          query: query,
        },
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  public static async getFlightInformation(
    token: string,
    date: Date,
    flight: string,
    arrival: boolean = true,
  ) {
    return await axios
      .get(
        `${API_URL_MISSIONS}/flight-information/autocomplete?date=${date.toISOString()}&&flight=${flight}&&arrival=${arrival}`,
        {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }
}

export default AutocompletionService;
