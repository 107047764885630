import React, { useEffect, useState } from 'react';
import ServiceTypeItem from './service-type-item.component';
import Transport from '../../assets/img/icon/Transport.svg';
import Transfert from '../../assets/img/icon/Transfert.svg';
import IService from '../../types/service.type';
import { t } from 'i18next';
import FormLabel from '../form/form-label/FormLabel.component';
import { missionMandatory } from '../../types/mission.type';

interface Props {
  service: IService;
  onChange: (property: string, value: any) => void;
}

const ServiceType: React.FC<Props> = (props) => {
  const [service, setService] = useState<IService>(props.service);
  const [selected, setSelected] = useState<string>('');

  useEffect(() => {
    if (props.service) {
      if (props.service.type.toLowerCase() === 'transfert') {
        setSelected('transfert');
      } else if (props.service.type.toLowerCase() === 'transport') {
        setSelected('transport');
      }
    }
  }, [props.service]);

  const handleSelectTransfert = () => {
    setSelected('transfert');
    setService({ ...service, type: 'transfert', label: 'transfert' });
    props.onChange('service', { ...service, type: 'transfert', label: 'transfert' });
    props.onChange('dropoffTime', null);
  };
  const handleSelectTransport = () => {
    setSelected('transport');
    setService({ ...service, type: 'transport', label: 'transport' });
    props.onChange('service', { ...service, type: 'transport', label: 'transport' });
  };
  return (
    <div>
      <h6>
        <FormLabel label={t(`form.service.title`)} mandatory={missionMandatory.service} />
      </h6>
      <div className='service-type'>
        <ServiceTypeItem
          id='service-transfert'
          className={selected == 'transfert' ? ' service-type-item--selected' : ''}
          selectService={handleSelectTransfert}
          serviceName={t('form.service.transfert.title')}
          serviceDescription={t('form.service.transfert.description')}
          prefix={Transfert}
        />
        <ServiceTypeItem
          id='service-transport'
          className={selected == 'transport' ? ' service-type-item--selected' : ''}
          selectService={handleSelectTransport}
          serviceName={t('form.service.transport.title')}
          serviceDescription={t('form.service.transport.description')}
          suffix={Transport}
        />
      </div>
    </div>
  );
};

export default ServiceType;
