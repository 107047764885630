import axios from 'axios';
import EventBus from '../../common/EventBus';
import camelcaseKeys from 'camelcase-keys';
import IPassenger from '../../types/passenger.type';
import IAddress from '../../types/address.type';

const API_PASSENGERS_URL = `${process.env.REACT_APP_API_PASSENGERS_URL}/passengers`;
const API_ADDRESSES_URL = `${process.env.REACT_APP_API_PASSENGERS_URL}/address`;

export class PassengerService {
  public static async createOrUpdate(accessToken: string, passenger: IPassenger) {
    if (passenger.id) {
      return this.update(accessToken, passenger);
    }
    return this.create(accessToken, passenger);
  }

  private static async create(accessToken: string, passenger: IPassenger) {
    return await axios
      .post(API_PASSENGERS_URL, passenger, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        if (response.status === 201) {
          return { message: 'Passager créé', data: response.data as IPassenger };
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : null;
        if (statusCode === 401) {
          EventBus.dispatch('logout');
        } else if (error.response) {
          throw new Error(error.response.data);
        } else {
          throw new Error();
        }
      });
  }

  private static async update(token: string, passenger: IPassenger) {
    return await axios
      .put(`${API_PASSENGERS_URL}/update?id=${passenger.id}`, passenger, {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          return { message: 'Passager modifié', data: response.data as IPassenger };
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : null;
        if (statusCode === 401) {
          EventBus.dispatch('logout');
        } else if (error.response) {
          throw new Error(error.response.data);
        } else {
          throw new Error();
        }
      });
  }

  public static async getPassengers(
    accessToken: string,
    search?: string,
  ): Promise<IPassenger[] | void> {
    return await axios
      .get(`${API_PASSENGERS_URL}/getPassengers?search=${encodeURIComponent(search ?? "")}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        return camelcaseKeys(response.data, { deep: true }) as IPassenger[];
      })
      .catch((err) => {
        throw new Error(err);
      });
  }

  public static async getPassenger(
    accessToken: string,
    command_id: string | null,
  ): Promise<IPassenger> {
    return await axios
      .get(`${API_PASSENGERS_URL}/getPassenger?passengerId=${command_id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        return camelcaseKeys(response.data, { deep: true }) as IPassenger;
      })
      .catch((err) => {
        return err;
      });
  }

  public static async delete(accessToken: string, id: number) {
    return await axios
      .delete(`${API_PASSENGERS_URL}/delete?id=${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          return { message: 'Passager suprimé' };
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : null;
        if (statusCode === 401) {
          EventBus.dispatch('logout');
        } else {
          throw error.response.data;
        }
      });
  }

  public static async getPassengerAddress(
    accessToken: string,
    passenger_id: number,
  ): Promise<IAddress[]> {
    return await axios
      .get(`${API_ADDRESSES_URL}?id=${passenger_id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : null;
        if (statusCode === 401) {
          EventBus.dispatch('logout');
        } else if (error.response) {
          throw new Error(error.response.data);
        } else {
          throw new Error();
        }
      });
  }
}

export default PassengerService;
