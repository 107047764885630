import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './pages/login/login';
import Header from './components/header/Header.component';
import Navbar from './components/navbar/Navbar.component';
import Dashboard from './pages/dashboard/Dashboard';
import Passengers from './pages/passengers/Passengers';
import Estimate from './pages/estimate/Estimate';
import Loader from './components/loader/Loader.component';
import Reservation from './pages/reservation/Reservation';
import ErrorPage from './pages/error/Error';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import EventBus from './common/EventBus';
import AuthService from './services/user/auth.service';
import ProtectedRoute from './utils/ProtectedRoute';
import Footer from './components/footer/Footer.component';
import StaticPage from './pages/legalnotice/StaticPage';
import { useTranslation } from 'react-i18next';
import i18n from './i18n/config';
import { PERMISSIONS } from './types/permissions.enum';

function App() {
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { t } = useTranslation(['ns1', 'legalNotice', 'errors'], { i18n });

  useEffect(() => {
    initEventListeners();
    AuthService.ssoSilentLogin(instance);
  }, [instance]);

  function initEventListeners() {
    EventBus.on('login', login);
    EventBus.on('logout', logout);
    EventBus.on('loading', loading);
    EventBus.on('notLoading', notLoading);
    EventBus.on('modalIsClose', modalIsClose);
    EventBus.on('modalIsOpen', modalIsOpen);
    EventBus.on('sidebar', sidebar);
    EventBus.on('notSidebar', notSidebar);
  }

  const modalIsClose = () => {
    setIsOpenModal(false);
  };

  const modalIsOpen = () => {
    setIsOpenModal(true);
  };

  const loading = () => {
    setIsLoading(!isLoading);
  };

  const notLoading = () => {
    setIsLoading(false);
  };

  const sidebar = () => {
    setIsSideBarOpen(true);
  };

  const notSidebar = () => {
    setIsSideBarOpen(false);
  };

  const login = () => {
    AuthService.login(instance);
  };
  const logout = () => {
    AuthService.logout(instance);
  };

  return (
    <React.Fragment>
      <Routes>
        <Route path='/error' element={<ErrorPage />} />
        <Route path='*' element={
          <React.Fragment>
            <AuthenticatedTemplate>
              <div className='page-global'>
                {isSideBarOpen && <div className='sidebar-background' />}
                <Loader loading={isLoading} />
                <Navbar />
                <div className='page-content'>
                  <Header />
                  <div className='page-component'>
                    <Routes>
                      <Route path='/' element={<Navigate replace to='/dashboard' />} />
                      <Route path='/dashboard' element={<Dashboard state_modal={isOpenModal} />} />
                      <Route path='/passenger' element={<Passengers />} />
                      <Route
                        path='/estimate/*'
                        element={
                          <ProtectedRoute permission={PERMISSIONS['Quotes.Create']}>
                            <Estimate state_modal={isOpenModal} />
                          </ProtectedRoute>
                        }
                      />
                      <Route path='/reservation' element={<Reservation state_modal={isOpenModal} />} />
                      <Route
                        path='/legal-notice'
                        element={
                          <StaticPage
                            title={t('legalNotice.title', { ns: 'legalNotice' })}
                            content={t('legalNotice.content', { ns: 'legalNotice' })}
                          />
                        }
                      />
                    </Routes>
                  </div>
                  <Footer />
                </div>
              </div>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <Login />
            </UnauthenticatedTemplate>
          </React.Fragment>
        } />
      </Routes>
    </React.Fragment>
  );
}

export default App;
