import React from 'react';
import usePermissions from '../config/usePermission';
import Forbidden from '../pages/forbidden/Forbidden';
import { PERMISSIONS } from '../types/permissions.enum';

type ProtectedRouteProps = {
  permission: PERMISSIONS;
  children: React.ReactNode;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ permission, children }) => {
  const hasPermission = usePermissions(permission);

  if (hasPermission === false) {
    return <Forbidden />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
