import { getAccessToken } from '../utils/getAccessToken';
import { useMsal } from '@azure/msal-react';
import UserService from '../services/user/user.service';
import { useEffect, useState } from 'react';

const getDispatch = () => {
  const [dispatch, setDispatch] = useState<string>('')
  const { instance } = useMsal();
  async function getDispatch() {
    return await getAccessToken(instance)
      .then(async (accessToken) => {
        return await UserService.getMe(accessToken).then((user) => {
          if (!user) return '';
          return user.dispatch;
        })
      })
      .catch((error) => {
        throw new Error(error);
      })
  }

  useEffect(() => {
    getDispatch()
      .then((dispatch) => {
        setDispatch(dispatch ?? '');
      })
      .catch((error) => {
        console.log(error);
      })
  }, [])

  return dispatch
};

export default getDispatch;