import React, { useEffect, useState } from 'react';
import ArticleForm from '../article/ArticleForm.component';
import profile from '../../assets/img/icon/profile.svg';
import children from '../../assets/img/icon/children.svg';
import infant from '../../assets/img/icon/infant.svg';
import childSeat from '../../assets/img/icon/child-seat.svg';
import babySeat from '../../assets/img/icon/baby-seat.svg';
import cabinBaggage from '../../assets/img/icon/cabin-baggage.svg';
import checkedBaggage from '../../assets/img/icon/checked-baggage.svg';
import whellchair from '../../assets/img/icon/whellchair.svg';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import IPassenger from '../../types/passenger.type';
import PassengerCredentialsComponent from './PassengerCredentials.component';
interface Props {
  passenger: IPassenger;
  updatePassager: (property: string, value: any) => void;
  setPassengerFromInput: (value: any) => void;
  setPassengersAutocompletion: (value: any) => void;
  handleGetPassenger: (value: string) => void;
  handleSidebarPassengerFormOpen: () => void;
  handle_change: (value: IPassenger) => void;
  handlePassengerDetailsSidebar: () => void;
  passengerLabel: string;
  passengersAutocompletion: IPassenger[] | undefined;
}

const PassengerDetailsFormComponent: React.FC<Props> = (props) => {
  const { t } = useTranslation('ns1', { i18n });
  const [passagerDetails, setPassagerDetails] = useState({} as IPassenger);
  const [maxChildSeats, setMaxChildSeats] = useState(0);
  const [maxBabySeats, setMaxBabySeats] = useState(0);

  useEffect(() => {
    if (props.passenger) {
      setPassagerDetails(props.passenger);
      if (props.passenger.childrens) {
        setMaxChildSeats(props.passenger.childrens);
      }
      if (props.passenger.infants) {
        setMaxBabySeats(props.passenger.infants);
      }
    }
  }, [props.passenger]);

  return (
    <div className='passenger-details-form' data-testid='passenger-details-form'>
      <h6>{t(`form.passenger.details.title-add-additional-passengers`)}</h6>
      <PassengerCredentialsComponent 
        passenger={props.passenger}
        updatePassager={props.updatePassager}
        setPassengerFromInput={props.setPassengerFromInput}
        setPassengersAutocompletion={props.setPassengersAutocompletion}
        handleGetPassenger={props.handleGetPassenger}
        handleSidebarPassengerFormOpen={props.handleSidebarPassengerFormOpen}
        handle_change={props.handle_change}
        passengerLabel={props.passengerLabel}
        passengersAutocompletion={props.passengersAutocompletion}
        isInSidebar={true}
        handlePassengerDetailsSidebar={props.handlePassengerDetailsSidebar}
      />
      <ArticleForm
        id='adults'
        sufIcon={profile}
        title={t(`form.passenger.details.adult`)}
        min={0}
        update={(e) => {
          props.updatePassager('adults', e);
        }}
        value={passagerDetails?.adults ?? 0}
      />
      <ArticleForm
        id='childrens'
        sufIcon={children}
        title={t(`form.passenger.details.children`)}
        subTitle={t(`form.passenger.details.children-age`)}
        min={0}
        update={(e) => {
          props.updatePassager('childrens', e);
          setMaxChildSeats(e);
        }}
        value={passagerDetails?.childrens ?? 0}
      />
      <ArticleForm
        id='childSeats'
        titleIcon={childSeat}
        title={t(`form.passenger.details.child-seat`)}
        min={0}
        max={maxChildSeats}
        update={(e) => {
          props.updatePassager('childSeats', e);
        }}
        value={passagerDetails?.childSeats ?? 0}
      />
      <ArticleForm
        id='infants'
        sufIcon={infant}
        title={t(`form.passenger.details.infant`)}
        subTitle={t(`form.passenger.details.infant-age`)}
        min={0}
        update={(e) => {
          props.updatePassager('infants', e);
          setMaxBabySeats(e);
        }}
        value={passagerDetails?.infants ?? 0}
      />
      <ArticleForm
        id='babySeats'
        titleIcon={babySeat}
        title={t(`form.passenger.details.baby-seat`)}
        min={0}
        max={maxBabySeats}
        update={(e) => {
          props.updatePassager('babySeats', e);
        }}
        value={passagerDetails?.babySeats ?? 0}
      />
      <h6>{t(`form.passenger.details.title-add-luggages`)}</h6>
      <ArticleForm
        id='cabinBaggages'
        sufIcon={cabinBaggage}
        title={t(`form.passenger.details.cabin-baggage`)}
        min={0}
        update={(e) => {
          props.updatePassager('cabinBaggages', e);
        }}
        value={passagerDetails?.cabinBaggages ?? 0}
      />
      <ArticleForm
        id='checkedBaggages'
        sufIcon={checkedBaggage}
        title={t(`form.passenger.details.checked-baggage`)}
        min={0}
        update={(e) => {
          props.updatePassager('checkedBaggages', e);
        }}
        value={passagerDetails?.checkedBaggages ?? 0}
      />
      <ArticleForm
        id='whellchairs'
        sufIcon={whellchair}
        title={t(`form.passenger.details.wheelchair`)}
        min={0}
        update={(e) => {
          props.updatePassager('whellchairs', e);
        }}
        value={passagerDetails?.whellchairs ?? 0}
      />
    </div>
  );
};

export default PassengerDetailsFormComponent;
