import copyrights from '../../assets/images/ic_round-copyright.svg';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/config';
import { Link } from 'react-router-dom';

const Footer = () => {
  const { t } = useTranslation('ns1', { i18n });

  return (
    <div id='footer' data-testid='footer' className='footer-chabe'>
      <div className='footer-chabe__item d-flex justify-content-between'>
        <div className='footer-chabe__item__rights'>
          <img
            className='footer-chabe__item__rights__img'
            src={copyrights}
            alt={t('accessibility.alt.copyrights')}
          />
          <p className='footer-chabe__item__rights__text body-medium-small'>{t('footer.text')}</p>
        </div>
        <Link className='footer-chabe__item__legal' to={'/legal-notice'}>
          <p className='footer-chabe__item__legal__text body-medium-small'>{t('footer.legal')}</p>
        </Link>
      </div>
    </div>
  );
};

export default Footer;
