import React from 'react';
import MissionStatusEnum, { MissionStatus } from '../../types/mission-status.enum';
import arrowRightSVG from '../../assets/img/icon/arrow-right.svg';

export interface Props {
  status: MissionStatus;
  onClick?: () => void;
}

const StatusButton = (props: Props) => {
  return (
    <button
      disabled={!props.onClick}
      data-testid='quotation-available-button'
      className={`mission-row-details__main__status-button${
        !props.onClick ? '--not-clickable' : ''
      }`}
      style={MissionStatusEnum.getButtonStyleColor(props.status)}
      onClick={props.onClick}
    >
      {MissionStatusEnum.getValueToString(props.status)}
      {props.onClick && <img src={arrowRightSVG} alt='quotation sent button'></img>}
    </button>
  );
};

export default StatusButton;
