import React from 'react';
import deleteInput from '../../../assets/img/icon/close-circle.svg';

interface Props {
  id: string;
  class_name?: string;
  name: string;
  label: string | null;
  placeholder: string;
  prefix: string | null;
  onDelete?: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKey?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  value?: string;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  suffix?: string | null;
  readonly ?: boolean | null;
  onSuffixClick?: () => void;
}

const InputText: React.FC<Props> = (props) => {
  if (props.prefix) {
    return (
      <>
        {props.label && <label hidden>{props.label}</label>}
        <div className='input-text'>
          <img className='input-text__prefix' alt='logo' src={props.prefix} />
          <label htmlFor={props.id} hidden>
            {props.label}
          </label>
          <input
            type='text'
            id={props.id}
            data-testid={props.id}
            name={props.name}
            placeholder={!props.readonly ? props.placeholder : ''}
            className={'input-text__input ' + props.class_name + (props.readonly ? ' input-text__readonly' : '')}
            autoComplete='off'
            onChange={props.onChange}
            onKeyUp={props.onKey}
            onBlur={props.onBlur}
            value={props.value || ''}
            readOnly={props.readonly ?? false}
          />
          {props.onDelete ? (
            <span
              data-testid={'delete-' + props.id}
              className='input-text__cross icon__clickable'
              onClick={(e) => {
                e.stopPropagation();
                if (props.onDelete) {
                  props.onDelete();
                }
              }}
            >
              <img className='input-text__suffix' alt='logo' src={deleteInput} />
            </span>
          ) : null}
          {props.suffix && (
            <img
              className={'input-text__suffix' + (props.onSuffixClick ? ' input-text__suffix__clickable' : '')}
              data-testid={props.id + '-suffix'}
              alt='logo'
              src={props.suffix}
              onClick={() => {
                if (props.onSuffixClick) {
                  props.onSuffixClick();
                }
              }}
            />
          )}
        </div>
      </>
    );
  }

  return (
    <>
      {props.label ? (
        <label htmlFor={props.id} className='text-light'>
          {props.label}
        </label>
      ) : (
        <> </>
      )}
      <div className='input-text'>
        <input
          type='text'
          id={props.id}
          data-testid={props.id}
          name={props.name}
          placeholder={!props.readonly ? props.placeholder : ''}
          className={'input-text__input input-text__input--without-prefix ' + props.class_name + (props.readonly ? ' input-text__readonly' : '')}
          onKeyDown={props.onKey}
          onChange={props.onChange}
          onBlur={props.onBlur}
          value={props.value || ''}
          readOnly={props.readonly ?? false}
        />
        <span className='input-text__cross' onClick={props.onDelete}>
          &#x2715;
        </span>
        {props.suffix && (
          <img
            className={'input-text__suffix' + (props.onSuffixClick ? ' input-text__suffix__clickable' : '')}
            data-testid={props.id + '-suffix'}
            alt='logo'
            src={props.suffix}
            onClick={() => {
              if (props.onSuffixClick) {
                props.onSuffixClick();
              }
            }}
          />
        )}
      </div>
    </>
  );
};

export default InputText;
