import React from 'react';
import { NavLink } from 'react-router-dom';
import iconDashboard from '../../assets/img/navbar/nav-home-icon.svg';
import logoChabeNavbar from '../../assets/img/navbar/chabe-logo.svg';
import passengerIcon from '../../assets/img/navbar/passenger-icon.svg';
import { useTranslation } from 'react-i18next';

const Navbar = () => {
  const { t } = useTranslation();

  return (
    <nav id='navbar' className='navbar-chabe'>
      <menu>
        <li>
          <NavLink
            id='nav-dashboard'
            to={'/dashboard'}
            className={({ isActive }) => (isActive ? 'nav-link is-active' : 'nav-link')}
          >
            <img src={iconDashboard} alt={t('accessibility.alt.icon-dashboard')} />
          </NavLink>
        </li>

        <li>
          <NavLink
            id='nav-passenger'
            to={'/passenger'}
            className={({ isActive }) => (isActive ? 'nav-link is-active' : 'nav-link')}
          >
            <img src={passengerIcon} alt={t('accessibility.alt.icon-dashboard')} />
          </NavLink>
        </li>
      </menu>

      <img
        className='navbar-logo'
        src={logoChabeNavbar}
        alt={t('accessibility.alt.logo-chabe-mini')}
      />
    </nav>
  );
};

export default Navbar;
