import React from 'react';
import i18n from 'i18next';

const Forbidden: React.FC = () => {
  return (
    <div data-testid='forbidden-page'>
      <h1>{i18n.t('forbidden.title')}</h1>
      <h4>{i18n.t('forbidden.message')}</h4>
    </div>
  );
};

export default Forbidden;
