import React, { ReactNode } from 'react';
import cross from '../../assets/img/icon/cross.svg';

interface Props {
  children: ReactNode;
  state: boolean;
  handleClose?: () => void;
}

const Modal: React.FC<Props> = (props) => {
  if (props.state) {
    return (
      <div className={'popup'} data-testid={'popup'}>
        <div className='popup__modale'>
          <img className='popup__cross' src={cross} alt='closs modal' onClick={props.handleClose} />
          {props.children}
        </div>
      </div>
    );
  }

  return <></>;
};

export default Modal;
