import React, { useEffect, useState } from 'react';
import Inverse from '../../assets/images/Inverse.svg';
import Address from '../address/address.component';
import prefixDeparture from '../../assets/images/depart.svg';
import prefixArrival from '../../assets/images/arrival.svg';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import IMission, { missionMandatory } from '../../types/mission.type';
import IStep from '../../types/step.type';
import addRound from '../../assets/img/icon/add-round.svg';
import Button from '../button/Button.component';
import FormLabel from '../form/form-label/FormLabel.component';
import TransportFormComponent from '../transport/TransportForm.component';
import ITransport from '../../types/transport.type';
import IAddress from '../../types/address.type';

interface TripProps {
  kind: string;
  class_name: string;
  mission: IMission;
  updateMission: (property: string, value: any) => void;
  minSteps?: number;
  maxSteps?: number;
}

const Trip: React.FC<TripProps> = (props) => {
  const [steps, setSteps] = useState<IStep[]>([]);
  const [minStep, setMinSteps] = useState<number>(1);
  const [maxStep, setMaxSteps] = useState<number>(10);

  const INVERSE_STEP = (first_index: number, second_index: number) => {
    const stepsChanged: IStep[] = [...steps];

    const first_order = stepsChanged[first_index].order;
    stepsChanged[first_index].order = stepsChanged[second_index].order;
    stepsChanged[second_index].order = first_order;

    props.updateMission('steps', stepsChanged);
  };

  const HANDLE_TRANSPORT_CHANGE = (index: number, value: null | ITransport) => {
    const stepsChanged: IStep[] = [...steps]; // Utiliser le spread operator pour créer une copie du tableau

    const idx = steps.findIndex((step) => step.order === index);

    if (value === null) {
      delete stepsChanged[idx].transport;
    } else {
      stepsChanged[idx].transport = value;
    }
    props.updateMission('steps', stepsChanged);
  };

  const HANDLE_ADDRESS_CHANGE = (index: number, value: IAddress | null) => {
    const stepsChanged: IStep[] = [...steps]; // Utiliser le spread operator pour créer une copie du tableau

    const idx = steps.findIndex((step) => step.order === index);

    if (value === null) {
      stepsChanged.splice(idx, 1); // Supprimer l'élément s'il est null
    } else {
      const newStep: IStep = { order: index, address: value } as IStep;
      if (idx !== -1) {
        stepsChanged[idx] = newStep;
      } else {
        stepsChanged.push(newStep);
      }
    }
    props.updateMission('steps', stepsChanged);
  };

  const generateSteps = (steps: IStep[]): IStep[] => {
    while (steps.length < (props.minSteps ?? minStep)) {
      steps.push({ order: 0.5, address: { value: '' } } as IStep);
    }
    return steps;
  };

  const cleanSteps = (steps: IStep[]): IStep[] => {
    return steps.filter((step) => step.address.value !== '');
  };

  const reorderSteps = (steps: IStep[]): IStep[] => {
    const stepsSorted = steps.sort((step1, step2) => step1.order - step2.order);
    return stepsSorted.map((step, i) => {
      step.order = i;
      //step.address.type = 0;
      return step;
    });
  };

  const handleAddStep = (): void => {
    HANDLE_ADDRESS_CHANGE(0.5, { value: '' } as IAddress);
  };

  useEffect(() => {
    if (props.mission) {
      setSteps(reorderSteps(generateSteps(props.mission.steps ?? [])));
    }
  }, [props.mission]);

  useEffect(() => {
    if (props.minSteps) {
      setMinSteps(props.minSteps);
      setSteps(reorderSteps(generateSteps(cleanSteps(props.mission.steps ?? []))));
    }
    if (props.maxSteps) {
      setMaxSteps(props.maxSteps);
    }
  }, [props.minSteps, props.maxSteps]);

  const { t } = useTranslation('ns1', { i18n });

  return (
    <div className='trip-component'>
      <div className='trip-container-title-fields'>
        <div className='trip-container-title'>
          <h6>
            <FormLabel label={t(`form.trip.title`)} mandatory={missionMandatory.steps} />
          </h6>
          {steps.length < maxStep && (
            <Button
              id='add-step'
              handleClick={handleAddStep}
              type='button'
              className='trip-component-add-step-button'
              pref={addRound}
              text={t(`form.trip.add-step`)}
            />
          )}
        </div>
        <div className='trip-container d-flex align-items-center'>
          <div data-testid='reverse-div' className='trip d-flex flex-column'>
            {steps.map((step, i) => {
              let prefix = prefixDeparture;
              let id = 'step';
              if (i === 0) {
                prefix = prefixDeparture;
                id = 'departure';
              } else if ((steps.length ?? 0) - 1 === i) {
                prefix = prefixArrival;
                id = 'arrival';
              }
              let inputProps = {};
              if (steps.length > minStep) {
                inputProps = {
                  onDelete: function (): void {
                    HANDLE_ADDRESS_CHANGE(i, null);
                  },
                };
              }

              return (
                <>
                  {i > 0 ? <div className={'trip-container__divider__' + (i - 1)}></div> : ''}
                  <Address
                    id={id}
                    name={id}
                    label={null}
                    class_name={props.class_name}
                    update={function (value: IAddress): void {
                      HANDLE_ADDRESS_CHANGE(i, value);
                    }}
                    {...inputProps}
                    placeholder={t(`form.trip.${id}`)}
                    prefix={prefix}
                    address={step.address}
                    id_passenger={props.mission.passengers && props.mission.passengers[0] && props.mission.passengers[0].id}
                  />
                </>
              );
            })}
          </div>
        </div>
      </div>
      <div className='trip-container-inverse'>
        {steps.map((step, i) => {
          if (i > 0) {
            return (
              <div className='pointer' key={i}>
                <img
                  onClick={() => {
                    INVERSE_STEP(i - 1, i);
                  }}
                  src={Inverse}
                  alt='inverse'
                  data-testid='inverse'
                />
              </div>
            );
          }
        })}
      </div>
      <div className='trip-container-transport' data-testid='trip-container-transport'>
        <div className='trip-container-title'>
          <h6>
            <FormLabel label={t(`form.trip.transport.title`)} />
          </h6>
        </div>
        {steps.map((step, i) => {
          return (
            <div className='trip-container-transport-step'>
              <TransportFormComponent
                arrival={i < steps.length / 2}
                date={props.mission.pickupDate}
                transport={step.transport}
                update={function (value: ITransport | null): void {
                  HANDLE_TRANSPORT_CHANGE(i, value);
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Trip;
