/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import IMission, { missionMandatory } from '../../types/mission.type';
import Button from '../button/Button.component';
import Sidebar from '../sidebar/Sidebar.component';
import PassengerDetailsFormComponent from '../passenger-details/PassengerDetailsForm.component';
import PassengerDetailsViewComponent from '../passenger-details/PassengerDetailsView.component';
import { getAccessToken } from '../../utils/getAccessToken';
import { useMsal } from '@azure/msal-react';
import PassengerService from '../../services/passengers/passenger.service';
import IPassenger from '../../types/passenger.type';
import PassengerItem from '../passenger-item/PassengerItem.component';
import FormLabel from '../form/form-label/FormLabel.component';
import PassengerForm from '../../components/passenger-form/PassengerForm.component';
import EventBus from '../../common/EventBus';
import addRound from '../../assets/img/icon/add-round.svg';
import CloseCircle from '../../assets/img/icon/close-circle.svg';
import PassengerCredentialsComponent from '../passenger-details/PassengerCredentials.component';

interface Props {
  mission: IMission;
  updateMission: (property: string, value: any) => void;
}

const Passenger: React.FC<Props> = (props) => {
  const [passengerLabel, setPassengerLabel] = useState<string>('');

  const [isSidebarDetailsOpen, setIsSidebarDetailsOpen] = useState<boolean>(false);
  const [isSidebarPassengerOpen, setIsSidebarPassengerOpen] = useState<boolean>(false);
  const [passenger, setPassenger] = useState<IPassenger | null>(null);
  const [passengerForm, setPassengerForm] = useState<IPassenger | null>(null);
  const [passengerDetailsForm, setPassengerDetailsForm] = useState<IPassenger>({} as IPassenger);
  const [passengersAutocompletion, setPassengersAutocompletion] = useState<
    IPassenger[] | undefined
  >(undefined);
  const { instance } = useMsal();

  const passengerFormRef = useRef<{ FORM_SUBMIT: () => void }>();

  const handleSidebarDetailsOpen = () => {
    setIsSidebarDetailsOpen(true);
  };

  const handleSidebarClose = () => {
    if (isSidebarPassengerOpen) {
      setIsSidebarPassengerOpen(false);
      setPassengerForm(null);
    } else {
      setIsSidebarDetailsOpen(false);
      setLabelFromPassenger(passenger ?? {} as IPassenger);
      setPassengerDetailsForm(passenger ?? {} as IPassenger);
    }
  };

  const handleSidebarValidate = () => {
    if (!isSidebarPassengerOpen) {
      props.updateMission('passengers', [passengerDetailsForm]);
      setIsSidebarDetailsOpen(false);
    } else if (passengerFormRef.current) {
      passengerFormRef.current.FORM_SUBMIT();
    }
  };

  const HANDLE_CHANGE_PASSENGER_DETAILS = (property: string, value: any) => {
    setPassengerDetailsForm((prevState) => ({ ...prevState, [property]: value }));
  };

  const HANDLE_CHANGE_PASSENGER_DETAILS_AND_VALIDATE = (property: string, value: any) => {
    HANDLE_CHANGE_PASSENGER_DETAILS(property, value);
    handleSidebarValidate();
  };

  const HANDLE_CHANGE_ALL_PASSENGER_DETAILS = (passenger: IPassenger) => {
    if (!passenger.adults || passenger.adults === 0) {
      passenger.adults = 1;
    }
    setPassengerDetailsForm(passenger);
    setLabelFromPassenger(passenger ?? {} as IPassenger);
    return passenger;
  };

  const HANDLE_CHANGE_ALL_PASSENGER_DETAILS_AND_VALIDATE = (passenger: IPassenger) => {
    props.updateMission('passengers', [HANDLE_CHANGE_ALL_PASSENGER_DETAILS(passenger)]);
  };

  const setPassengerFromInput = (value: any) => {
    let passengerToUpdate = passengerDetailsForm;
    if (!passengerToUpdate.adults || passengerToUpdate.adults === 0) {
      passengerToUpdate.adults = 1;
    }

    if (passengerToUpdate.id) {
      passengerToUpdate = {
        ...passengerToUpdate,
        id: undefined,
        lastname: value,
        firstname: '',
        phone: '',
        email: '',
        details: '',
        mobility: false,
      };
    } else {
      passengerToUpdate = {
        ...passengerToUpdate,
        lastname: value,
        firstname: '',
      };
    }

    setPassengerDetailsForm(passengerToUpdate);
    setLabelFromPassenger(passengerToUpdate);
    return passengerToUpdate;
  };

  const setPassengerFromInputAndValidate = (value: any) => {
    props.updateMission('passengers', [setPassengerFromInput(value)]);
  };

  const HANDLE_SUBMIT_PASSENGER_FORM = (passenger: IPassenger) => {
    props.updateMission('passengers', [passenger]);
    setIsSidebarPassengerOpen(false);
    setPassengerForm(null);
  };

  const handleGetPassenger = async (value: string) => {
    getAccessToken(instance).then((token) => {
      PassengerService.getPassengers(token, value)
        .then((response) => {
          setPassengersAutocompletion(response as IPassenger[]);
        })
        .catch((error) => {});
    });
  };

  const setLabelFromPassenger = (passenger: IPassenger) => {
    const labelPassenger = `${passenger.firstname ?? ''}${
      passenger.firstname && passenger.lastname ? ' ' : ''
    }${passenger.lastname ?? ''}`;
    setPassengerLabel(labelPassenger);
  }

  useEffect(() => {
    if (props.mission && props.mission.passengers && props.mission.passengers[0]) {
      setLabelFromPassenger(props.mission.passengers[0]);
      setPassenger(props.mission.passengers[0]);
      setPassengerDetailsForm(props.mission.passengers[0]);
    } else {
      setPassenger(null);
      setPassengerLabel('');
      setPassengerDetailsForm({} as IPassenger);
    }
  }, [props.mission]);

  const resetPassenger = () => {
    props.updateMission('passengers', null);
  };

  const handleCancelPassengerForm = () => {
    setIsSidebarPassengerOpen(false);
    setPassengerForm(null);
  };

  const handleSidebarPassengerFormOpen = () => {
    setPassengerForm(passengerDetailsForm);
    setIsSidebarPassengerOpen(true);
  };

  const handlePassengerDetailsSidebar = () => {
    setIsSidebarDetailsOpen(true);
  }

  useEffect(() => {
    if (isSidebarPassengerOpen || isSidebarDetailsOpen) {
      EventBus.dispatch('sidebar');
    } else {
      EventBus.dispatch('notSidebar');
    }
  }, [isSidebarPassengerOpen, isSidebarDetailsOpen]);

  return (
    <div id='passenger' data-testid='passenger'>
      <h6>
        <FormLabel label={t(`form.passenger.title`)} mandatory={missionMandatory.passengers} />
      </h6>
      <div className='d-flex'>
        <div className={`input-passenger-name ${passenger && 'input-passenger-name-background'}`} data-testid='input-passenger-name'>
          { !passenger ?
            <div className='input-passenger-details'>
              <Button
                pref={addRound}
                id='add-passengers'
                className='request-button-secondary request-button-secondary--border-blue'
                handleClick={handleSidebarDetailsOpen}
                text={t('form.passenger.details.button-sidebar')}
                type='button'
              />
            </div>
            :
            <PassengerCredentialsComponent 
              passenger={passengerDetailsForm}
              updatePassager={HANDLE_CHANGE_PASSENGER_DETAILS_AND_VALIDATE}
              setPassengerFromInput={setPassengerFromInputAndValidate}
              setPassengersAutocompletion={setPassengersAutocompletion}
              handleGetPassenger={handleGetPassenger}
              handleSidebarPassengerFormOpen={handleSidebarPassengerFormOpen}
              handle_change={HANDLE_CHANGE_ALL_PASSENGER_DETAILS_AND_VALIDATE}
              passengerLabel={passengerLabel}
              passengersAutocompletion={!isSidebarDetailsOpen ? passengersAutocompletion : undefined}
              isInSidebar={false}
              handlePassengerDetailsSidebar={handlePassengerDetailsSidebar}
            />
          }
          <PassengerDetailsViewComponent passager={passenger || {}} />
          
          {(isSidebarPassengerOpen || isSidebarDetailsOpen) && (
            <>
              <Sidebar onClose={handleSidebarClose} onValidate={handleSidebarValidate}>
                {passengerForm ? (
                  <PassengerForm
                    ref={passengerFormRef}
                    passenger={passengerForm}
                    update={HANDLE_CHANGE_ALL_PASSENGER_DETAILS_AND_VALIDATE}
                    onReset={handleCancelPassengerForm}
                    submit={HANDLE_SUBMIT_PASSENGER_FORM}
                    inSidebar={true}
                  />
                ) : (
                  <PassengerDetailsFormComponent
                    passenger={passengerDetailsForm}
                    updatePassager={HANDLE_CHANGE_PASSENGER_DETAILS}
                    setPassengerFromInput={setPassengerFromInput}
                    setPassengersAutocompletion={setPassengersAutocompletion}
                    handleGetPassenger={handleGetPassenger}
                    passengerLabel={passengerLabel}
                    passengersAutocompletion={passengersAutocompletion}
                    handleSidebarPassengerFormOpen={handleSidebarPassengerFormOpen}
                    handle_change={HANDLE_CHANGE_ALL_PASSENGER_DETAILS}
                    handlePassengerDetailsSidebar={handlePassengerDetailsSidebar}
                  />
                )}
              </Sidebar>
              <div className='loading sidebar__bg' />
            </>
          )}
        </div>
        { passenger &&
          <img 
            src={CloseCircle} alt="close-circle" 
            className='align-self-start m-1 icon__clickable'
            onClick={() => resetPassenger()}
          />
        }
        </div>
    </div>
  );
};

export default Passenger;
