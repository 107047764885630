import IMission from '../../types/mission.type';
import axios from 'axios';
import EventBus from '../../common/EventBus';
import MissionStatusEnum from '../../types/mission-status.enum';

const API_URLS = {
  quotationsRequests: `${process.env.REACT_APP_API_MISSIONS_URL}/missions/quotationsRequests`,
  bookingRequests: `${process.env.REACT_APP_API_MISSIONS_URL}/missions/bookingRequests`,
  todayBookings: `${process.env.REACT_APP_API_MISSIONS_URL}/missions/todayBookings`,
  upcomingBookings: `${process.env.REACT_APP_API_MISSIONS_URL}/missions/upcomingBookings`,
  pastBookings: `${process.env.REACT_APP_API_MISSIONS_URL}/missions/pastBookings`,
  denyMission: `${process.env.REACT_APP_API_MISSIONS_URL}/missions/deny`,
  acceptMission: `${process.env.REACT_APP_API_MISSIONS_URL}/missions/accept`,
};

type ApiType = keyof typeof API_URLS;

export class MissionService {
  private static async fetchMissions(
    token: string,
    api: ApiType,
    search?: string,
    orderBy?: string,
    direction?: string,
    index?: number,
    limit?: number,
  ): Promise<IMission[]> {
    const headers = {
      authorization: `Bearer ${token}`,
    };
    const params = {
      search: search,
      orderBy: orderBy,
      direction: direction,
      index: index,
      limit: limit,
    };
    try {
      const res = await axios.get(API_URLS[api], { headers, params });
      EventBus.dispatch('updateToast');
      return res.data.map((mission: any) => ({
        ...mission,
        pickupDate: new Date(mission.pickupDate),
        dropoffDate: new Date(mission.dropoffDate),
        pickupTime: new Date(mission.pickupTime),
        status: MissionStatusEnum.getMissionsStatusFromString(mission.status),
      }));
    } catch (error: any) {
      const statusCode = error.response ? error.response.status : null;
      if (statusCode === 401) {
        EventBus.dispatch('logout');
      }

      sessionStorage.setItem('error', `errors.list.${api}.get`);
      EventBus.dispatch('updateToast');
      return [];
    }
  }

  public static async getQuotationsRequests(
    token: string,
    search?: string,
    orderBy?: string,
    direction?: string,
    index?: number,
    limit?: number,
  ): Promise<IMission[]> {
    return await MissionService.fetchMissions(
      token,
      'quotationsRequests',
      search,
      orderBy,
      direction,
      index,
      limit,
    );
  }

  public static async getBookingRequests(
    token: string,
    search?: string,
    orderBy?: string,
    direction?: string,
    index?: number,
    limit?: number,
  ): Promise<IMission[]> {
    return await MissionService.fetchMissions(
      token,
      'bookingRequests',
      search,
      orderBy,
      direction,
      index,
      limit,
    );
  }

  public static async getTodayBookings(
    token: string,
    search?: string,
    orderBy?: string,
    direction?: string,
    index?: number,
    limit?: number,
  ): Promise<IMission[]> {
    return await MissionService.fetchMissions(
      token,
      'todayBookings',
      search,
      orderBy,
      direction,
      index,
      limit,
    );
  }

  public static async getUpcomingBookings(
    token: string,
    search?: string,
    orderBy?: string,
    direction?: string,
    index?: number,
    limit?: number,
  ): Promise<IMission[]> {
    return await MissionService.fetchMissions(
      token,
      'upcomingBookings',
      search,
      orderBy,
      direction,
      index,
      limit,
    );
  }

  public static async getPastBookings(
    token: string,
    search?: string,
    orderBy?: string,
    direction?: string,
    index?: number,
    limit?: number,
  ): Promise<IMission[]> {
    return await MissionService.fetchMissions(
      token,
      'pastBookings',
      search,
      orderBy,
      direction,
      index,
      limit,
    );
  }

  public static async denyReservation(
    accessToken: string,
    command_id: number,
    mission_id: number,
    confirmation_date: Date,
  ) {
    const requestBody = {
      command_id: command_id,
      mission_id: mission_id,
      confirmation_date: confirmation_date,
    };

    return await axios
      .post(API_URLS.denyMission, requestBody, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          return { message: 'Réservation refusé avec succès' };
        }
      })
      .catch(() => {
        return { message: 'Erreur lors du refus du réservation' };
      });
  }
}
