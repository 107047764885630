import React from 'react';
import arrow_right from '../../assets/images/arrow-right.svg';
import IStep from '../../types/step.type';
import stepIcon from '../../assets/img/raw/step.svg';
import startIcon from '../../assets/img/raw/start.svg';
import arrivalIcon from '../../assets/img/raw/arrival.svg';
import down from '../../assets/img/raw/arrow-down-2.svg';
import TransportViewComponent from '../transport/TransportView.component';

export enum TripViewMode {
  inline,
  column,
  column_light,
}

interface Props {
  steps?: IStep[];
  mode?: TripViewMode;
}

const TripViewComponent: React.FC<Props> = (props) => {
  if (props.mode && props.mode === TripViewMode.column) {
    return (
      <>
        {props.steps &&
          props.steps.map((step, i) => {
            let icon = stepIcon;
            let alt = 'step';
            if (i === 0) {
              icon = startIcon;
              alt = 'start';
            } else if ((props.steps?.length ?? 0) - 1 === i) {
              icon = arrivalIcon;
              alt = 'arrival';
            }
            return (
              <div className='trip-step-column' data-testid={alt} key={i}>
                <div className='value'>
                  <img className='' src={icon} alt={alt} />
                  {step.address.value}
                </div>
                <div className='transport'>
                  {step.transport && (
                    <TransportViewComponent transport={step.transport}></TransportViewComponent>
                  )}
                </div>
              </div>
            );
          })}
      </>
    );
  } else if (props.mode && props.mode === TripViewMode.column_light) {
    return (
      <>
        {props.steps && (
          <>
            <div>
              <img src={down} alt='arrow down' />
            </div>
            <div className='trip-step-column-light__departure-arrival'>
              <div className='trip-step-truncate' data-testid='departure'>
                {props.steps[0].address.value}
              </div>
              <div className='trip-step-truncate' data-testid='arrival'>
                {props.steps[props.steps.length - 1].address.value}
              </div>
            </div>
          </>
        )}
      </>
    );
  } else {
    return (
      <>
        {props.steps?.map((step, i) => {
          return (
            <span key={i}>
              {i > 0 ? (
                <img
                  src={arrow_right}
                  alt='arrow-right'
                  className='trip-arrow-right'
                  data-testid='trip-arrow-right'
                />
              ) : (
                ''
              )}
              {step.address.value}
            </span>
          );
        })}
      </>
    );
  }
};

export default TripViewComponent;
