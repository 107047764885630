import React, { useEffect, useState } from 'react';
import add from '../../assets/img/icon/add-grey.svg';
import remove from '../../assets/img/icon/remove.svg';

interface ArticleProps {
  id: string;
  sufIcon?: string;
  titleIcon?: string;
  title?: string;
  subTitle?: string;
  value: number | undefined;
  min?: number;
  max?: number;
  reduceMargin?: boolean;
  update: (value: number) => void;
}

const ArticleForm: React.FC<ArticleProps> = (props) => {
  const [count, setCount] = useState(props.value || 0);
  const [displayInput, setDisplayInput] = useState(true);

  const sufIcon = props.sufIcon ? <img src={props.sufIcon} /> : null;
  const titleIcon = props.titleIcon ? <img src={props.titleIcon} /> : null;

  const handleDecrement = () => {
    if (typeof props.min !== 'number' || count > props.min) {
      setCount(count - 1);
      props.update(count - 1);
    }
  };

  const handleIncrement = () => {
    if (typeof props.max !== 'number' || count < props.max) {
      setCount(count + 1);
      props.update(count + 1);
    }
  };

  useEffect(() => {
    if (typeof props.value === 'number') {
      setCount(props.value);
    }
    if (typeof props.max === 'number' && count > props.max) {
      props.update(props.max);
    }
    if (typeof props.min === 'number' && count < props.min) {
      props.update(props.min);
    }
    if (
      typeof props.min === 'number' &&
      typeof props.max === 'number' &&
      props.min === props.max &&
      !props.value
    ) {
      setDisplayInput(false);
    } else {
      setDisplayInput(true);
    }
  }, [props.max, props.min, props.value]);

  return (
    <div className={`article-form ${props.reduceMargin ? 'reduce-margin' : ''}`}>
      <div className='article-header'>
        <div className='article-icon'>{sufIcon}</div>
        <div className='article-title'>
          <div className={`title ${props.titleIcon ? 'title-with-icon' : ''}`}>
            {titleIcon} {props.title}
          </div>
          <div className='sub-title'>{props.subTitle}</div>
        </div>
      </div>
      <div className='article-input'>
        {displayInput && (
          <div className='article-input-container'>
            <button data-testid={props.id + '-decrement'} type='button' onClick={handleDecrement}>
              <img src={remove} />
            </button>
            <input data-testid={props.id} type='number' value={count} readOnly />
            <button data-testid={props.id + '-increment'} type='button' onClick={handleIncrement}>
              <img src={add} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ArticleForm;
