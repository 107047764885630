import React, { useEffect } from 'react';
import calendar from '../../../../assets/images/chevron_bottom.svg';
import { transformTime, convertTimeToDateGMT } from '../../../../helpers/TransformDate.helper';

interface Props {
  id?: string;
  class_name?: string;
  onChange: (e: Date) => void;
  value?: Date;
}

const PickerTime: React.FC<Props> = (props) => {
  const [time, setTime] = React.useState('');
  const [timer, setTimer] = React.useState<NodeJS.Timeout | null>(null);
  const [isFirefox, setIsFirefox] = React.useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    if (userAgent.indexOf('firefox') > -1) {
      setIsFirefox(true);
    }
  }, []);

  useEffect(() => {
    if (props.value) {
      setTime(transformTime(props.value));
    }
  }, [props.value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (timer) {
      clearTimeout(timer);
    }
    setTime(e.target.value);
    const newTimer = setTimeout(() => {
      props.onChange(convertTimeToDateGMT(e.target.value));
    }, 300);
    setTimer(newTimer);
  };

  return (
    <div className={`picker_time ${isFirefox ? 'picker_time__without_image' : ''}`}>
      <input
        type='time'
        name='hour'
        onChange={handleChange}
        id={props.id ?? 'time'}
        data-testid={props.id ?? 'time'}
        data-id='time'
        className='picker_time__input'
        value={time}
      />
      <img src={calendar} alt='chevron' className='picker_time__image' />
    </div>
  );
};

export default PickerTime;
