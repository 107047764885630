import Button from '../../button/Button.component';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import EventBus from '../../../common/EventBus';

interface Props {
  open_modal?: () => void;
  validate?: () => Promise<boolean>;
  text_confirm: string;
}

const ActionRequest: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const handleClick = () => {
    EventBus.dispatch('modalIsClose');
    navigate('/dashboard');
  };

  return (
    <div className='action-request' data-testid='action-request'>
      <Button
        id='cancel'
        data-testid='cancel'
        className='action-request__button-cancel request-button-secondary request-button-secondary--border-blue '
        text={t('form.action.cancel')}
        handleClick={handleClick}
      />
      <Button
        id='send'
        data-testid='send'
        className='request-button-primary'
        text={props.text_confirm}
        handleClick={async () => {
          if (props.validate && (await props.validate())) {
            EventBus.dispatch('modalIsOpen');
          }
          if (!props.validate) {
            EventBus.dispatch('modalIsOpen');
          }
        }}
        type='button'
      ></Button>
    </div>
  );
};

export default ActionRequest;
