import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

export interface DropdownElement {
  id?: string;
  value: string;
  label: string;
}

interface DropdownProps {
  elements: DropdownElement[];
  handleSelectedElement: (e: any) => void;
  selectedElement: DropdownElement | null;
  beforeIcon?: string | null;
  placeHolder: string;
  customClass?: string;
  showScrollIndicators?: boolean;
}

const Dropdown = (props: DropdownProps) => {
  const showScrollIndicators = props.showScrollIndicators ?? true;
  const { t } = useTranslation('ns1', { i18n });
  const containerClassName = `component-dropdown${props.customClass || ''}`;
  const selectContainerClassName = `react-select-container${props.customClass || ''}`;

  const customStyles = {
    menuList: (baseStyles: any) => ({
      ...baseStyles,
      overflow: showScrollIndicators ? 'auto' : 'hidden',
      '::-webkit-scrollbar': {
        width: '0.5rem',
        height: '10rem',
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: 'darkgrey',
        height: '15rem',
      },
      '::-webkit-scrollbar-button': {
        display: 'none',
      },
    }),
  };

  return (
    <div className={containerClassName} data-testid='component-dropdown'>
      <div className={`custom-dropdown${props.customClass || ''}`}>
        {props.beforeIcon && (
          <img
            className='select-img'
            src={props.beforeIcon}
            alt={t('accessibility.alt.dropdown-prefix-icon')}
          />
        )}
        <label htmlFor='selectionDropdown' hidden>
          selectionDropdown
        </label>
        <Select
          id='selectionDropdown'
          data-testid='selectionDropdown'
          options={props.elements}
          placeholder={props.placeHolder}
          className={selectContainerClassName}
          classNamePrefix='react-select'
          name='car'
          onChange={props.handleSelectedElement}
          value={props.selectedElement}
          styles={customStyles}
        />
      </div>
    </div>
  );
};

export default Dropdown;
