// src/pages/error/Error.js
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/config';
import logoChabe from '../../assets/images/logo-text.png';
import errorBackground from '../../assets/images/error-bg.jpg';

const ErrorPage = () => {
  const [searchParams] = useSearchParams();
  const errorType = searchParams.get('type');
  const { t } = useTranslation('errors', { i18n });

  const getErrorTitle = () => {
    switch (errorType) {
      case 'token.expired':
        return t(errorType + '.title', { ns: 'errors' });
      default:
        return t('unknownError.title', { ns: 'errors' });
    }
  };

  const getErrorMessage = () => {
    switch (errorType) {
      case 'token.expired':
        return t(errorType + '.message', { ns: 'errors' });
      default:
        return t('unknownError.message', { ns: 'errors' });
    }
  };

  return (
    <div className="error-page">
      <div className="background_branding_container">
        <img className="background_background_image" src={errorBackground} alt="Illustration" aria-hidden="true" />
      </div>
      <div className="panel" id="panel">
        <div className="panel-container">
          <img src={logoChabe} />
          <h1>{getErrorTitle()}</h1>
          <p>{getErrorMessage()}</p>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
