import UserService from '../services/user/user.service';
import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { getAccessToken } from '../utils/getAccessToken';
import { PERMISSIONS } from '../types/permissions.enum';

const usePermissions = (permissionName: PERMISSIONS) => {
  const [hasPermission, setHasPermission] = useState<boolean | undefined>(undefined);
  const { instance } = useMsal();

  async function userPermissions() {
    return await getAccessToken(instance)
      .then(async (accessToken) => {
        return await UserService.getMe(accessToken).then((user) => {
          if (!user) return [];
          return user.rights;
        });
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  useEffect(() => {
    userPermissions()
      .then((permissions) => {
        let hasPermission = false;
        permissions.forEach((permission) => {
          if (PERMISSIONS[permissionName].includes(permission)) {
            hasPermission = true;
          }
        });
        setHasPermission(hasPermission);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return hasPermission;
};

export default usePermissions;
