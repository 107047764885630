import React from 'react';
import logo_chabe from '../../assets/img/navbar/chabe-logo.svg';
import circle from '../../assets/img/loading/circle.svg';

interface Props {
  loading: boolean;
}

const Loader: React.FC<Props> = (props) => {
  if (props.loading) {
    return (
      <div className='loader' data-testid='loader'>
        <img className='loader__img' src={logo_chabe} alt='logo chabé' />
        <img className='loader__svg' src={circle} alt='circle loading' />
      </div>
    );
  }

  return <></>;
};

export default Loader;
