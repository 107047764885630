import React from 'react';
import IAddress, { AddressType } from '../../types/address.type';
import { t } from 'i18next';
import InputText from '../form/inputs/InputText.component';
import Dropdown from '../dropdown/Dropdown.component';
import remove from '../../assets/img/icon/Trash.svg';
import Address from '../address/address.component';

interface Props {
  address: IAddress;
  index: number;
  update: (key: string, value: any) => void;
  delete: () => void;
}

const PassengerAddressesFormItem: React.FC<Props> = (props) => {
  return (
    <div
      key={'item-' + props.index}
      className='passenger-form__address__item'
      data-testid='passenger-form-address-item'
    >
      <div className='passenger-form__address__content'>
        <div className='passenger-form__address__data'>
          <div className='passenger-form__address__data__name'>
            <Dropdown
              customClass='passenger-form__address__data__type'
              data-testid='address-type'
              placeHolder={t('form.passengerCreate.address.types.title')}
              elements={[
                {
                  value: AddressType.home.toString(),
                  label: t('form.passengerCreate.address.types.home'),
                },
                {
                  value: AddressType.work.toString(),
                  label: t('form.passengerCreate.address.types.work'),
                },
              ]}
              selectedElement={{
                value: props.address.type?.toString() ?? '',
                label: t(
                  'form.passengerCreate.address.types.' + AddressType[props.address.type ?? 0],
                ).toUpperCase(),
              }}
              handleSelectedElement={(value) => {
                props.update('type', Number(value.value));
              }}
            />
            <InputText
              label=''
              id='address-title-passenger'
              data-testid='address-title-passenger'
              prefix=''
              class_name='input-text__wide passenger-form__address__data__name__input'
              name='address-title-passenger'
              placeholder={t('form.passengerCreate.address.placeholderTitle')}
              onChange={(e) => {
                props.update('title', e.target.value);
              }}
              value={props.address.title ?? ''}
            />
          </div>
          <div className='passenger-form__address__data__value'>
            <Address
              id='address-passenger'
              name='address-passenger'
              label={null}
              class_name='passenger-form__address__data__value__input'
              update={function (value: IAddress): void {
                props.update('address', value);
              }}
              placeholder={t('form.passengerCreate.address.placeholder')}
              prefix={null}
              address={props.address}
            />
          </div>
        </div>
        <div className='passenger-form__address__data__delete'>
          <img
            onClick={() => props.delete()}
            src={remove}
            alt='delete'
            data-testid='passenger-form-address-item-delete'
          />
        </div>
      </div>
    </div>
  );
};

export default PassengerAddressesFormItem;
