import React from 'react';
import { t } from 'i18next';
import CancelButton from '../cancel_button/CancelButton.component';
import IMission from '../../../types/mission.type';
import MissionViewComponent from '../../mission/MissionView.component';

interface Props {
  quote?: object;
  mission: IMission;
  id: number;
  handleCancel?: () => void;
  handleRefuse?: (command_id: number, mission_id: number) => void;
  handleApprove?: (command_id: number) => void;
  kind: string;
}

const Cancel: React.FC<Props> = (props) => {
  return (
    <div className='cancel' data-testid='cancel-component'>
      <h5 className='cancel__title'>{t(`form.${props.kind}.title`)}</h5>
      <div className='cancel__info'>
        <p className='cancel__info-title'>{t(`form.${props.kind}.your_request`)}</p>
        <MissionViewComponent mission={props.mission} />
        <div className=''>
          <hr />
          <p className='confirmation__info-ref body-small'></p>
          <p className='confirmation__info-text body-small'>
            <strong>{t(`form.${props.kind}.please_cancel`)}</strong>
          </p>
        </div>
      </div>
      <CancelButton
        textCancel={t(`form.${props.kind}.cancel`)}
        textApprove={t(`form.${props.kind}.confirm`)}
        handleCancel={props.handleCancel}
        handleRefuse={() => {
          props.handleRefuse &&
            props.handleRefuse(props.id, parseInt(props.mission.id ?? ''));
        }}
        handleApprove={() => {
          props.handleApprove && props.handleApprove(props.id);
        }}
      />
    </div>
  );
};

export default Cancel;
