import React, { useEffect, useState } from 'react';
import Dropdown, { DropdownElement } from '../dropdown/Dropdown.component';
import IVehicleType from '../../types/vehicleType.type';
import carIcon from '../../assets/img/icon/car.svg';
import { useTranslation } from 'react-i18next';
import { VehicleService } from '../../services/vehicles/vehicle.service';
import IMission, { missionMandatory } from '../../types/mission.type';
import camelcaseKeys from 'camelcase-keys';
import FormLabel from '../form/form-label/FormLabel.component';
import { getAccessToken } from '../../utils/getAccessToken';
import { useMsal } from '@azure/msal-react';

interface SelectVehicleProps {
  mission: IMission;
  updateMission: (property: string, value: any) => void;
}

const SelectVehicle = (props: SelectVehicleProps) => {
  const { t } = useTranslation();
  const [vehicles, setVehicles] = useState([] as IVehicleType[]);
  const [selectedElement, setSelectedElement] = useState<DropdownElement | null>(null);
  const { instance } = useMsal();

  useEffect(() => {
    getAccessToken(instance).then((token) => {
      VehicleService.getAllVehicleTypes(token).then((res) => {
        setVehicles(camelcaseKeys(res, { deep: true }));
      });
    });
  }, []);

  useEffect(() => {
    if (props.mission?.vehicle) {
      const selectedVehicle = vehicles.find(
        (vehicleType: IVehicleType) =>
          parseInt(props.mission.vehicle.type?.id ?? '0') == parseInt(vehicleType.id),
      );
      if (selectedVehicle) {
        setSelectedElement({
          value: selectedVehicle.id,
          label: selectedVehicle.label,
        });
      }
    }
  }, [props.mission]);

  return (
    <div id='selectVehicle' data-testid='selectVehicle' className='select-vehicle'>
      <h6>
        <FormLabel
          label={t(`components.selectVehicle.title`)}
          mandatory={missionMandatory.vehicle}
        />
      </h6>
      <Dropdown
        elements={vehicles.map((vehicle: IVehicleType) => {
          return { value: vehicle.id, label: vehicle.label };
        })}
        handleSelectedElement={(value) => {
          props.updateMission('vehicle', { type: { id: value.value, label: value.label } });
        }}
        selectedElement={selectedElement}
        beforeIcon={carIcon}
        placeHolder={t('components.selectVehicle.placeholder')}
      />
    </div>
  );
};

export default SelectVehicle;
