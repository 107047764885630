import React from 'react';
import Button from '../../button/Button.component';
import { t } from 'i18next';
import EventBus from '../../../common/EventBus';
import IMission from '../../../types/mission.type';
import MissionViewComponent from '../../mission/MissionView.component';

interface Props {
  handleConfirm?: () => void;
  mission?: IMission;
  kindOfPage?: string;
}

const Confirmation: React.FC<Props> = (props) => {
  return (
    <div className='confirmation'>
      <h5 className='confirmation__title'>{t(`form.${props.kindOfPage}.confirm_title`)}</h5>
      <div className="confirmation__info">
        {props.mission && <MissionViewComponent mission={props.mission} />}
        <hr />
        <p className="confirmation__info-text body-small">
          <strong>{t('form.quotes.please')}</strong>
        </p>
        <p className="confirmation__info-text body-small">{t('form.quotes.confirmation')}</p>
      </div>
      <div className="confirmation__buttons">
      <Button
          id='cancel'
          className='request-button-secondary confirmation__buttons-cancel'
          text={t('form.action.cancel')}
          handleClick={() => EventBus.dispatch('modalIsClose')}
        />
        <Button
          id='confirm'
          className='request-button-primary confirmation__buttons-confirm'
          text={t(`form.${props.kindOfPage}.confirm`)}
          type='submit'
        />
      </div>
    </div>
  );
};

export default Confirmation;
