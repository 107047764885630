import React from 'react';
import { t } from 'i18next';
import { MissionStatus } from '../../types/mission-status.enum';
import IMission from '../../types/mission.type';
import PassengerDetailsViewComponent from '../passenger-details/PassengerDetailsView.component';
import TripViewComponent, { TripViewMode } from '../trip/TripView.component';
import StatusButton from '../button/StatusButton.component';
import { transformDateSimpleFormat } from '../../helpers/TransformDate.helper';

interface Props {
  mission: IMission;
  handlePopInQuotation: () => void;
}

const RowDetails = (props: Props) => {
  return (
    <div className='mission-row-details'>
      <div
        className='mission-row-details__main '
        data-testid={'mission-row-details' + props.mission.id}
      >
        <div className='mission-row-details__main__external_reference_passenger'>
          {props.mission.externalReference && (
            <>
              <div className='mission-row-details__main__external_reference'>
                <span className='mission-row-details__title'>
                  {t('components.booking.row.externalReference')}
                </span>
                <span className='mission-row-details__value mission-row-details__reference'>
                  {props.mission.externalReference}
                </span>
              </div>
            </>
          )}
          {props.mission.passengers && props.mission.passengers[0] && (
            <>
              <div className='mission-row-details__main__passenger'>
                <PassengerDetailsViewComponent
                  passager={props.mission.passengers[0]}
                  light={true}
                />
              </div>
            </>
          )}
        </div>

        <div className='mission-row-details__main__addresses'>
          <TripViewComponent steps={props.mission.steps} mode={TripViewMode.column} />
        </div>
        <div className='mission-row-details__main__buttons'>
          {props.mission.status === MissionStatus.QuotationAvailable ? (
            <StatusButton onClick={props.handlePopInQuotation} status={props.mission.status} />
          ) : (
            <StatusButton status={props.mission.status} />
          )}
        </div>
      </div>
      {
        props.mission.description && (
          <div className='mission-row-details__bottom'>
            <span className='mission-row-details__title'>
              {t('components.booking.row.requestDescription')}
            </span>
            <span className='mission-row-details__value'>{props.mission.description}</span>
          </div>
        )
      }
      {
        props.mission.dateCanceled && (
          <div className='mission-row-details__bottom'>
            <span className='mission-row-details__value'>{t('components.booking.row.canceledDescription')}{transformDateSimpleFormat(props.mission.pickupDate)}</span>
          </div>
        )
      }
    </div>
  );
};

export default RowDetails;
