export enum AddressType {
  work,
  home,
}

export default interface IAddress {
  [key: string]: any;
  id?: number; //Pas nécessaire pour l'autocompletion
  id_passager?: number; //Uniquement coté back
  title?: string; //Pas présent lors du retour de google
  type?: AddressType; //Pas présent lors du retour de google
  value: string;
  place_id?: string;
  timezone?: string;
  location?: never;
}
