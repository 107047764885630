import axios from 'axios';
import EventBus from '../../common/EventBus';
import IQuote from '../../types/quote.type';
import camelcaseKeys from 'camelcase-keys';
import IMission from '../../types/mission.type';
import { ReservationService } from '../reservations/reservation.service';

const API_URL_QUOTE = `${process.env.REACT_APP_API_MISSIONS_URL}/quotes`;

export class QuoteService extends ReservationService {
  public static async create(accessToken: string, mission: IMission) {
    const requestBody: IMission = mission;

    return await axios
      .post(API_URL_QUOTE, requestBody, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        if (response.status === 201) {
          return { message: 'Devis créé' };
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : null;
        if (statusCode === 401) {
          EventBus.dispatch('logout');
        } else {
          throw error.response.data;
        }
      });
  }

  public static async update(token: string, id: string, mission: IMission) {
    const requestBody: IMission = mission;

    return await axios
      .put(`${API_URL_QUOTE}/update`, requestBody, {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 201) {
          return { message: 'Devis modifié' };
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : null;
        if (statusCode === 401) {
          EventBus.dispatch('logout');
        } else {
          throw error.response.data;
        }
      });
  }

  public static async denyQuotation(
    accessToken: string,
    command_id: number,
    mission_id: number,
    confirmation_date: Date,
  ) {
    const requestBody = {
      command_id: command_id,
      mission_id: mission_id,
      confirmation_date: confirmation_date,
    };

    return await axios
      .post(`${API_URL_QUOTE}/deny`, requestBody, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          return { message: 'Devis refusé avec succès' };
        }
      })
      .catch((err) => {
        return { message: 'Erreur lors du refus du devis' };
      });
  }

  public static async acceptQuotation(
    accessToken: string,
    command_id: number,
    mission_id: number,
    confirmation_date: Date,
  ) {
    const requestBody = {
      command_id: command_id,
      mission_id: mission_id,
      confirmation_date: confirmation_date,
    };

    return await axios
      .post(`${API_URL_QUOTE}/accept`, requestBody, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          return { message: 'Devis accepté avec succès' };
        }
      })
      .catch((error) => {
        const statusCode = error.response ? error.response.status : null;
        if (statusCode === 401) {
          EventBus.dispatch('logout');
        } else {
          return { message: "Erreur lors de l'acceptation du devis" };
        }
      });
  }

  public static async getQuotation(
    accessToken: string,
    command_id: string | null,
  ): Promise<IQuote> {
    return await axios
      .get(`${API_URL_QUOTE}/getQuote?quoteId=${command_id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        return camelcaseKeys(response.data, { deep: true }) as IQuote;
      })
      .catch((err) => {
        return err;
      });
  }
}

export default QuoteService;
