export enum PERMISSIONS {
  'Missions.Read',
  'Missions.Create',
  'Missions.Update',
  'Missions.ReadOthers',
  'Missions.PriviligedAccount',
  'Quotes.Read',
  'Quotes.Create',
  'Quotes.Update',
  'Quotes.ReadOthers',
  'Quotes.PriviligedAccount',
  'ArrivalsBoard.Read',
  'ArrivalsBoard.ReadOthers',
  'Passengers.Read',
  'Passengers.Create',
  'Passengers.Update',
  'Passengers.ReadOthers',
  'Passengers.PriviligedAccount',
  'Workflow.Missions.PostRequest',
  'Missions.RequestDescription',
  'Missions.VIPMeetGreat',
  'Quotes.RequestDescription',
  'Quotes.VIPMeetGreat',
  'Missions.PassengerPhoneMandatory'
}
