import React from 'react';

interface Props {
  id: string;
  label: string;
  value?: boolean;
  className?: string | '';
  onChange?: (value: any) => void;
}

const Checkbox = (props: Props) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) props.onChange(e.target.checked);
  };
  return (
    <div className={'checkbox-wrapper-33 ' + props.className}>
      <label className='checkbox'>
        <input
          id={props.id}
          data-testid={props.id}
          className='checkbox__trigger visuallyhidden'
          type='checkbox'
          onChange={onChange}
          checked={props.value}
        />
        <span className='checkbox__symbol'>
          <svg
            aria-hidden='true'
            className='icon-checkbox'
            width='28px'
            height='28px'
            viewBox='0 0 28 28'
            version='1'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M4 14l8 7L24 7'></path>
          </svg>
        </span>
        <p className='checkbox__textwrapper'>{props.label}</p>
      </label>
    </div>
  );
};

export default Checkbox;
