import ReactDOM from 'react-dom/client';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import './scss/main.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CustomNavigationClient } from './utils/NavigationClient';
import { useEffect, useState } from 'react';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './config/auth.config';

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    if (
      event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS
    ) {
      const payload = event.payload;
      if (payload && typeof payload === 'object' && 'account' in payload) {
        const account = payload.account;
        if (account) {
          msalInstance.setActiveAccount(account);
        }
      }
    }
  });

  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

  root.render(
    <BrowserRouter>
      <ClientSideNavigation pca={msalInstance}>
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </ClientSideNavigation>
    </BrowserRouter>,
  );
});

function ClientSideNavigation({ pca, children }: any) {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(async (url, options) => {
    navigate(url, options);
    return true;
  });
  pca.setNavigationClient(navigationClient);

  // react-router-dom v6 doesn't allow navigation on the first render - delay rendering of MsalProvider to get around this limitation
  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    setFirstRender(false);
  }, []);

  if (firstRender) {
    return null;
  }

  return children;
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
