import axios from 'axios';

const API_URL_PASSENGERS = `${process.env.REACT_APP_API_PASSENGERS_URL}`;

export class TimezoneService {
  public static async getTimezone(token: string, placeId?: string) {
    return await axios
      .get(`${API_URL_PASSENGERS}/google-places/timezone`, {
        params: {
          placeid: placeId,
        },
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }


  public static convertTZ(localDate: Date, tzString: string) {
    return new Date(localDate.toLocaleString("en-US", {timeZone: tzString}))
  }
}

export default TimezoneService;
