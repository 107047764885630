import ITransport, { TransportType } from '../../types/transport.type';
import clock from '../../assets/img/icon/clock.svg';
import information from '../../assets/img/icon/information.svg';
import error from '../../assets/img/icon/error.svg';
import React from 'react';
import { t } from 'i18next';

interface Props {
  date?: Date;
  transport: ITransport;
  error?: boolean;
  update?: (value: ITransport | null) => void;
}

const TransportDetailsFormComponent: React.FC<Props> = (props) => {
  if (!props.date && props.transport.type !== TransportType.INFORMATION) {
    return (
      <div className='transport-details-form__date' data-testid='transport-details-form__date'>
        <img src={information} alt='information' /> {t(`form.trip.transport.details.error.date`)}
      </div>
    );
  }

  if (props.error) {
    return (
      <div className='transport-details-form__error' data-testid='transport-details-form__error'>
        <img src={error} alt='error' /> {t(`form.trip.transport.details.error.unknown-number`)}
      </div>
    );
  }

  if (props.transport.from || props.transport.to) {
    return (
        <div className='transport-details-form' data-testid='transport-details-form'>
          {(props.transport.from || props.transport.to) && (
            <>
              <span className='value'>{props.transport.from}</span>
              <span className='value'>{props.transport.to}</span>
            </>
          )}
          {props.transport.terminal && (
            <>
              <span className='title'>
                {props.transport.type === TransportType.TRAIN
                  ? t('components.booking.row.track')
                  : t('components.booking.row.terminal')}{' '}
                :
              </span>
              <span className='value'>{props.transport.terminal}</span>
            </>
          )}
          {props.transport.hour && (
            <span className='hour'>
              <img src={clock} alt='clock' />
              {props.transport.hour}
            </span>
          )}
        </div>
    );
  }
  return <></>;
};

export default TransportDetailsFormComponent;
