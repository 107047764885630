import React, { useEffect, useState } from 'react';
import IPassenger from '../../types/passenger.type';
import IAddress from '../../types/address.type';
import { getAccessToken } from '../../utils/getAccessToken';
import PassengerService from '../../services/passengers/passenger.service';
import EventBus from '../../common/EventBus';
import FormLabel from '../form/form-label/FormLabel.component';
import { t } from 'i18next';
import AddRound from '../../assets/img/icon/AddRound.svg';
import { useMsal } from '@azure/msal-react';
import IPassengerErrors from '../../types/passenger.error.type';
import PassengerAddressesFormItem from './PassengerAddressesFormItem.component';

interface ChildProps {
  passenger_id?: number;
  submit?: (passenger: IPassenger) => void | undefined;
  onChange: (property: string, value: any) => void;
}

const PassengerAddressesForm: React.FC<ChildProps> = (props: ChildProps) => {
  const { instance } = useMsal();
  const [errors, setErrors] = useState({} as IPassengerErrors);
  const [addresses, setAddresses] = useState([] as IAddress[]);

  const DELETE_ADDRESS = (index: number) => {
    const newAddresses = JSON.parse(JSON.stringify(addresses));
    newAddresses.splice(index, 1);
    setAddresses(newAddresses);
    props.onChange('addresses', newAddresses);
  };

  const HANDLE_CHANGE_ADDRESS = (property: string, value: any, index: number) => {
    const newAddresses = JSON.parse(JSON.stringify(addresses));
    if(property === 'address'){
      newAddresses[index].value = value.value;
      newAddresses[index].timezone = value.timezone;
      newAddresses[index].location = value.location;
    } else {
      newAddresses[index][property] = value;
    }
    setAddresses(newAddresses);
    props.onChange('addresses', newAddresses);
  };

  const GET_ADDRESSES = async () => {
    if (!props.passenger_id) {
      setAddresses([]);
      return;
    }
    try {
      const token = await getAccessToken(instance);
      const response = await PassengerService.getPassengerAddress(token, props.passenger_id ?? 0);
      setAddresses(response);
      props.onChange('addresses', response);
    } catch (error: any) {
      if (!error.errors && error.message) {
        setErrors({ technical: error.message });
      } else {
        setErrors(error.errors);
      }
      EventBus.dispatch('notLoading');
    }
  };

  const ADD_ADDRESS = () => {
    const newAddresses = JSON.parse(JSON.stringify(addresses));
    newAddresses.push({
      title: '',
      type: undefined,
      value: '',
      id_passager: props.passenger_id,
    } as IAddress);
    setAddresses(newAddresses);
  };

  useEffect(() => {
    GET_ADDRESSES();
  }, [props.passenger_id]);

  return (
    <>
      <h6>
        <FormLabel label={t(`form.passengerCreate.address.title`)} mandatory={false} />
      </h6>
      <div className='passenger-form__address'>
        {addresses && addresses.length > 0 && (
          <>
            {addresses.map((address, index) => (
              <PassengerAddressesFormItem
                key={'key' + index}
                address={address}
                index={index}
                update={(key, value) => {
                  HANDLE_CHANGE_ADDRESS(key, value, index);
                }}
                delete={() => {
                  DELETE_ADDRESS(index);
                }}
              />
            ))}
          </>
        )}
        <div
          onClick={ADD_ADDRESS}
          id='btn-add-address'
          data-testid='btn-add-address'
          className='passenger-form__address__add'
        >
          <img src={AddRound} alt='' />
          <span className='body-normal-small text-uppercase'>
            {t(`form.passengerCreate.address.add`)}
          </span>
        </div>
      </div>
    </>
  );
};
export default PassengerAddressesForm;
