import React from 'react';

interface Props {
  id?: string;
  serviceName: string;
  serviceDescription: string;
  prefix?: string;
  suffix?: string;
  className?: string | null;
  selectService: () => void;
}

const ServiceTypeItem: React.FC<Props> = (props) => {
  const handleChangeService = () => {
    props.selectService();
  };

  return (
    <div
      id={props.id}
      data-testid={props.id}
      className={'service-type-item' + props.className}
      onClick={handleChangeService}
    >
      {props.prefix && (
        <img className='service-type-item__icon' alt='icon-transport' src={props.prefix} />
      )}
      <div className='service-type-item__infos'>
        <span className='body-normal-small service-type-item__infos__name'>
          {props.serviceName}
        </span>
        <span className='body-medium-small service-type-item__infos__description'>
          {props.serviceDescription}
        </span>
      </div>
      {props.suffix && (
        <img className='service-type-item__icon__suffix' alt='icon-transport' src={props.suffix} />
      )}
    </div>
  );
};

export default ServiceTypeItem;
