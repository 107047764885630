import React from 'react';

interface Props {
  id: string;
  class_name?: string;
  name: string;
  value: string;
  placeholder: string;
  label?: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const Textarea: React.FC<Props> = (props) => {
  return (
    <div>
      <label htmlFor={props.id}>{props.label}</label>
      <textarea
        rows={4}
        onChange={props.onChange}
        placeholder={props.placeholder}
        id={props.id}
        data-testid={props.id}
        data-textarea_id={props.id}
        className={'textarea'}
        name={props.name}
        value={props.value}
      ></textarea>
    </div>
  );
};

export default Textarea;
