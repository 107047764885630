import React from 'react';
import { t } from 'i18next';
import Button from '../../button/Button.component';
interface Props {
  handleCancel?: () => void;
  handleRefuse?: () => void;
  handleApprove?: () => void;
}

const ValidationButton: React.FC<Props> = (props) => {
  return (
    <div className='validation-buttons'>
      <hr className='validation-buttons__divider' />
      <div className='validation-buttons__container'>
        <Button
          id='cancel'
          data-testid='cancel'
          className='request-button-secondary validation-buttons__cancel'
          text={t('form.action.cancel')}
          handleClick={props.handleCancel}
        />
        <Button
          id='refuse'
          data-testid='refuse'
          className='request-button-primary validation-buttons__refuse'
          text={t('form.action.refuse')}
          handleClick={props.handleRefuse}
        />
        <Button
          id='confirm'
          data-testid='confirm'
          className='request-button-primary validation-buttons__confirm'
          text={t('form.action.approve')}
          handleClick={props.handleApprove}
        />
      </div>
    </div>
  );
};

export default ValidationButton;
