import React from 'react';
import i18n, { t } from 'i18next';
import IMission from '../../types/mission.type';
import TripViewComponent, { TripViewMode } from '../trip/TripView.component';
import { transformDate, transformTime, transformDateFormat  } from '../../helpers/TransformDate.helper';
import ellipse from '../../assets/images/ellispe.svg';
import PassengerDetailsViewComponent from '../passenger-details/PassengerDetailsView.component';

interface Props {
  mission: IMission;
}

const MissionViewComponent: React.FC<Props> = (props) => {
  return (
    <div className='mission-view'>
      {props.mission?.folderId && (
        <div className='mission-view__info'>
          <p className='mission-view__info-label'>{t('form.folder.title')} : </p>
          <p>{props.mission?.folderId}</p>
        </div>
      )}
      <div className='mission-view__info'>
        <p className='mission-view__info-label'>
          {t(`form.service.${props.mission.service?.type}.title`.toLowerCase(), '')} :{' '}
        </p>
        <p className='mission-view__info-value'>
          <TripViewComponent steps={props.mission.steps} mode={TripViewMode.inline} />
        </p>
      </div>
      <div className='mission-view__info'>
        <p className='mission-view__info-label'>{t('form.quotes.date')}</p>
        <p className='mission-view__info-value'>{transformDateFormat(props.mission.pickupDate, i18n.language)}</p>
      </div>
      <div className='mission-view__info'>
        <p className='mission-view__info-label'>{t('form.quotes.time')}</p>
        <p className='mission-view__info-value'>
          {transformTime(props.mission.pickupTime)}
          {props.mission?.service?.type?.toLowerCase() === 'transport' && (
            <>
              {' '}<img src={ellipse} alt='ellipse' />{' '}
              {transformTime(props.mission.dropoffTime)}
            </>
          )}
        </p>
      </div>
      <div className='mission-view__info'>
        <p className='mission-view__info-label'>{t('form.quotes.passenger')}</p>
        <p className='mission-view__info-value'>
          {props.mission.passengers && props.mission.passengers[0] && (
            <>
              {props.mission.passengers[0].lastname
                ? props.mission.passengers[0].lastname + ' '
                : ''}
              {props.mission.passengers[0].firstname ? props.mission.passengers[0].firstname : ''}
            </>
          )}
          <div>
            {props.mission.passengers && props.mission.passengers[0] && (
              <>
                <PassengerDetailsViewComponent
                  passager={props.mission.passengers[0]}
                  light={true}
                />
              </>
            )}
          </div>
        </p>
      </div>
      <div className='mission-view__info'>
        <p className='mission-view__info-label'>{t('form.quotes.car')}</p>
        <p className='mission-view__info-value'>{props.mission.vehicle?.type?.label}</p>
      </div>
      {props.mission?.externalReference && (
        <div className='mission-view__info'>
          <p className='mission-view__info-label'>{t('form.projectReference.title')} : </p>
          <p className='mission-view__info-value'>{props.mission?.externalReference}</p>
        </div>
      )}
    </div>
  );
};

export default MissionViewComponent;
