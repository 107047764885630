import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en1 from './en/en1.json';
import en2 from './en/en2.json';
import legalnoticeEN from './en/legalnoticeEN.json';
import messagesEN from './en/messagesEN.json';
import errorsEN from './en/errors.json';
import fr1 from './fr/fr1.json';
import fr2 from './fr/fr2.json';
import legalnoticeFR from './fr/legalnoticeFR.json';
import messagesFR from './fr/messagesFR.json';
import errorsFR from './fr/errors.json';

const isTestEnvironment = process.env.NODE_ENV === 'test';

i18n.use(initReactI18next).init({
  defaultNS: ['ns1', 'ns2', 'legalNotice', 'messages', 'errors'],
  ns: ['ns1', 'ns2', 'legalNotice', 'messages', 'errors'],
  preload: ['en', 'fr'],
  debug: !isTestEnvironment, // useful to see events that occur during development
  lng: 'en', // if you're using a language detector, do not define the lng option
  fallbackLng: ['en', 'fr'],
  resources: {
    en: {
      ns1: en1,
      ns2: en2,
      legalNotice: legalnoticeEN,
      messages: messagesEN,
      errors: errorsEN,
    },
    fr: {
      ns1: fr1,
      ns2: fr2,
      legalNotice: legalnoticeFR,
      messages: messagesFR,
      errors: errorsFR,
    },
  },
  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
  react: {
    bindI18n: 'loaded languageChanged editorSaved',
    bindI18nStore: 'added removed',
    useSuspense: true,
  },
});

export default i18n;
