/* eslint-disable react/display-name */
import React, { useEffect, useImperativeHandle, useState } from 'react';
import i18n, { t } from 'i18next';
import InputText from '../form/inputs/InputText.component';
import Textarea from '../form/textarea/Textarea.component';
import Checkbox from '../form/checkbox/checkbox.component';
import Button from '../../components/button/Button.component';
import IPassenger, { passengerMandatory } from '../../types/passenger.type';
import EventBus from '../../common/EventBus';
import { getAccessToken } from '../../utils/getAccessToken';
import { useMsal } from '@azure/msal-react';
import colors from '../../scss/utils/_variables.module.scss';
import PassengerService from '../../services/passengers/passenger.service';
import { Avatar } from '@mui/material';
import FormLabel from '../form/form-label/FormLabel.component';
import IPassengerErrors from '../../types/passenger.error.type';
import Toast from '../toast/Toast.component';
import { errorsToMessage } from '../../utils/errorsUtils';
import deletePassenger from '../../assets/img/icon/delete-passenger.svg';
import PassengerAddressesForm from './PassengerAddressesForm.component';
import Modal from '../../components/modal/Modal.component';
import IMissionErrors from '../../types/mission.error.type';
import usePermissions from '../../config/usePermission';
import { PERMISSIONS } from '../../types/permissions.enum';

interface Props {
  passenger: IPassenger;
  update?: (value: IPassenger) => void;
  submit?: (passenger: IPassenger) => void;
  onReset: () => void;
  inSidebar?: boolean;
}

const PassengerForm = React.forwardRef((props: Props, ref: any) => {
  const { instance } = useMsal();
  const [passenger, setPassenger] = useState({} as IPassenger);
  const [errors, _setErrors] = useState<IMissionErrors>({} as IMissionErrors);

  const errorsRef = React.useRef(errors);
  const setErrors = (data: IMissionErrors) => {
    errorsRef.current = data;
    _setErrors(data);
  };
  const [errorsMessage, setErrorsMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState<string | null>('');
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const hasPermissionPhoneMandatory = usePermissions(PERMISSIONS['Missions.PassengerPhoneMandatory']);


  useImperativeHandle(ref, () => ({
    FORM_SUBMIT,
  }));

  const openConfirmation = () => {
    setConfirmationOpen(true);
  };

  const closeConfirmation = () => {
    setConfirmationOpen(false);
  };

  const HANDLE_VALIDATE = (values: IPassenger) => {
    let errors = {} as IPassengerErrors;
    for (const [key, value] of Object.entries(passengerMandatory)) {
      if (value && (!values.hasOwnProperty(key) || !values[key] || values[key] === '')) {
        errors = { ...errors, [key]: 'mandatory' };
      }
      if (!values.phone && hasPermissionPhoneMandatory) {
        errors = { ...errors, phone: 'mandatory' };
      }
    }
    setErrorsMessage('');
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const HANDLE_SET_ERRORS_MESSAGE = async () => {
    setErrorsMessage(errorsToMessage(errors));
  };

  const HANDLE_CHANGE = (property: string, value: any) => {
    setPassenger((prevState) => ({ ...prevState, [property]: value }));
  };

  const FORM_SUBMIT = async () => {
    setSuccessMessage(null);
    // Vérifie si la validation du passager est réussie
    if (HANDLE_VALIDATE(passenger)) {
      // Indique le début du chargement
      EventBus.dispatch('loading');

      try {
        // Obtient le jeton d'accès
        const token = await getAccessToken(instance);

        // Crée ou met à jour le passager en utilisant le service
        const response = await PassengerService.createOrUpdate(token, passenger);

        // Si une fonction de soumission est fournie, appelez-la avec le passager
        if (response && response['data']) {
          setPassenger(response['data'] as IPassenger);
          if (props.submit) {
            props.submit(response['data'] as IPassenger);
          }
          if (props.update) {
            props.update(response['data'] as IPassenger);
          }
        }

        if (passenger.id) {
          setSuccessMessage('success.passenger.update');
        } else {
          setSuccessMessage('success.passenger.create');
        }

        // Indique que le chargement est terminé
        EventBus.dispatch('notLoading');
      } catch (error: any) {
        setErrorsMessage('');
        // Gère les erreurs, en affichant un message technique s'il n'y a pas d'erreurs spécifiques
        if (!error.errors && error.message) {
          setErrors({ technical: error.message });
        } else if (!error.errors) {
          setErrors({ technical: 'Failed' });
        } else {
          setErrors(error.errors);
        }

        // Indique que le chargement est terminé, même en cas d'erreur
        EventBus.dispatch('notLoading');
      }
    }
  };

  const DELETE_PASSENGER = () => {
    const id = passenger.id;
    if (id !== undefined) {
      getAccessToken(instance).then((token) => {
        PassengerService.delete(token, id)
          .then((response) => {
            if (props.submit) {
              props.submit(passenger);
            }
            props.onReset();
          })
          .catch((error) => {
            if (!error.errors && error.message) {
              setErrors({ technical: error.message });
            } else {
              setErrors(error.errors);
            }
          });
      });
    }
  };

  const handleReset = () => {
    setPassenger({} as IPassenger);
  };
  // == Hooks ===============================================================

  useEffect(() => {
    if (passenger?.id !== props.passenger?.id) {
      setErrors({} as IPassengerErrors);
      setSuccessMessage(null);
    }
    setPassenger(props.passenger);
  }, [props.passenger]);

  useEffect(() => {
    i18n.on('languageChanged', HANDLE_SET_ERRORS_MESSAGE);

    return () => {
      i18n.off('languageChanged', HANDLE_SET_ERRORS_MESSAGE);
    };
  }, [i18n]);

  useEffect(() => {
    console.log('handle set error');
    HANDLE_SET_ERRORS_MESSAGE();
  }, [errors]);

  // == Render ===============================================================

  return (
    <div className='passenger-form' data-testid='passenger-form'>
      <h5>
        <div className='header-form'>
          <div className='passenger-item'>
            <Avatar
              sx={{
                textTransform: 'uppercase',
                bgcolor: colors?.primaryColor,
                color: colors?.lightGrey,
                fontFamily: 'EuclidCircularA-Semibold',
                fontSize: '14px',
              }}
            >
              {passenger && passenger.firstname?.charAt(0) + passenger.lastname?.charAt(0)}
            </Avatar>
            <span className='body-medium-small passenger-item__infos__name title-info'>
              {!passenger.id && <>{t('pages.passengers.createNewPassenger')}</>}
              {passenger.id && (
                <>
                  {props.passenger.firstname} {props.passenger.lastname}
                </>
              )}
            </span>
          </div>
          <div>
            {!props.inSidebar && props.passenger?.id && (
              <img src={deletePassenger} alt='' onClick={openConfirmation} />
            )}
          </div>
        </div>
      </h5>

      <hr className='passenger-form__divider' />
      <div className='field'>
        <h6>
          <FormLabel
            label={t(`form.passengerCreate.name`)}
            mandatory={passengerMandatory.lastname}
          />
        </h6>
        <InputText
          label=''
          id='name-passenger'
          data-testid='name-passenger'
          prefix=''
          class_name='text-passenger-name input-text__wide'
          name='passengers'
          placeholder={t('form.passengerCreate.placeholderName')}
          onChange={(e) => {
            HANDLE_CHANGE('lastname', e.target.value);
          }}
          value={passenger.lastname}
        />
      </div>
      <div className='field'>
        <h6>
          <FormLabel
            label={t(`form.passengerCreate.firstname`)}
            mandatory={passengerMandatory.firstname}
          />
        </h6>
        <InputText
          label=''
          id='firstname-passenger'
          data-testid='firstname-passenger'
          prefix=''
          class_name='text-passenger-name input-text__wide'
          name='passengers'
          placeholder={t('form.passengerCreate.placeholderFirstName')}
          onChange={(e) => {
            HANDLE_CHANGE('firstname', e.target.value);
          }}
          value={passenger.firstname}
        />
      </div>
      <div className='field'>
        <h6>
          <FormLabel label={t(`form.passengerCreate.email`)} mandatory={passengerMandatory.email} />
        </h6>
        <InputText
          label=''
          id='mail-passenger'
          data-testid='mail-passenger'
          prefix=''
          class_name='text-passenger-name input-text__wide'
          name='passengers'
          placeholder={t('form.passengerCreate.placeholderEmail')}
          onChange={(e) => {
            HANDLE_CHANGE('email', e.target.value);
          }}
          value={passenger.email}
        />
      </div>
      <div className='field'>
        <h6>
          <FormLabel label={t(`form.passengerCreate.phone`)} mandatory={hasPermissionPhoneMandatory} />
        </h6>
        <InputText
          label=''
          id='phone-passenger'
          data-testid='phone-passenger'
          prefix=''
          class_name='text-passenger-name input-text__wide'
          name='passengers'
          placeholder={t('form.passengerCreate.placeholderPhone')}
          onChange={(e) => {
            HANDLE_CHANGE('phone', e.target.value);
          }}
          value={passenger.phone}
        />
      </div>
      <div className='field'>
        <PassengerAddressesForm passenger_id={passenger.id} onChange={HANDLE_CHANGE} />
      </div>
      <div className='field'>
        <h6>
          <FormLabel
            label={t(`form.passengerCreate.detail`)}
            mandatory={passengerMandatory.details}
          />
        </h6>
        <Textarea
          label=''
          placeholder={t('form.passengerCreate.placeholderDetail')}
          id='detail-passenger'
          data-testid='detail-passenger'
          data-textarea_id='detail-passenger'
          name='detail-passenger'
          onChange={(e) => {
            HANDLE_CHANGE('details', e.target.value);
          }}
          value={passenger.details ?? ''}
        />
      </div>
      <div className='field'>
        <Checkbox
          data-testid='checkbox-mobility'
          id='mobility'
          label={t('form.passengerCreate.mobility')}
          value={passenger.mobility}
          onChange={(value) => {
            HANDLE_CHANGE('mobility', value);
          }}
        />
      </div>
      {Object.keys(errors).length > 0 && errorsMessage != '' && (
        <Toast kind='error' message={errorsMessage} disabledIcon={true} />
      )}
      {successMessage && successMessage != '' && (
        <Toast kind='success' message={t(successMessage)} disabledIcon={false} />
      )}
      {!props.inSidebar && (
        <div className='footer-new-passenger'>
          <Button
            id='cancel-new-passenger'
            data-testid='cancel-new-passenger'
            text={t('form.passengerCreate.cancel')}
            className='request-button-secondary new-passenger-save-btn'
            handleClick={() => {
              props.onReset();
              handleReset();
            }}
          />
          <Button
            id='save-new-passenger'
            data-testid='save-new-passenger'
            text={t('form.passengerCreate.save')}
            className='request-button-primary new-passenger-save-btn'
            handleClick={FORM_SUBMIT}
          />
        </div>
      )}

      <Modal state={confirmationOpen} handleClose={closeConfirmation}>
        <div className='modal-delete-passenger'>
          <h5 className='confirmation__title'>{t('form.action.delete')}</h5>
          <div className='confirmation__buttons'>
            <Button
              id='cancel'
              data-testid='cancel_delete_passenger'
              className='request-button-secondary  confirmation-button-passenger'
              text={t('form.action.cancel_delete_passenger')}
              handleClick={closeConfirmation}
            />
            <Button
              id='confirm'
              data-testid='delete_passenger'
              className='request-button-primary confirmation-button-passenger'
              text={t('form.action.delete_passenger')}
              type='submit'
              handleClick={DELETE_PASSENGER}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
});

export default PassengerForm;
