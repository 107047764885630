import React, { useEffect, useState } from 'react';
import { ToastContainer, toast, Id } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.minimal.css';
import error from '../../assets/images/error.svg';
import success from '../../assets/img/icon/success-colored.svg';

interface Props {
  message: string;
  kind: string;
  disabledIcon?: boolean;
}

const Toast: React.FC<Props> = (props) => {
  const [toastId, setToastId] = useState<Id | null>(null);

  const RENDER_TOAST_HTML = (html: string) => {
    return <div className='toast_html' dangerouslySetInnerHTML={{ __html: html }}></div>;
  };

  useEffect(() => {
    if (!toastId) {
      const newToastId = toast(RENDER_TOAST_HTML(props.message), {
        position: 'bottom-left',
        icon: props.disabledIcon ? (
          false
        ) : props.kind == 'success' ? (
          <img src={success} />
        ) : (
          <img src={error} />
        ),
        autoClose: false,
        closeButton: false,
        closeOnClick: true,
        draggable: false,
        theme: 'light',
        className: props.kind == 'success' ? 'Toastify__toast--success' : 'Toastify__toast--error',
        type: props.kind == 'success' ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
      });
      setToastId(newToastId);
    } else {
      toast.update(toastId, {
        render: () => RENDER_TOAST_HTML(props.message),
      });
    }
  }, [props.message]);

  return (
    <div className='toasted' data-testid='toast'>
      <ToastContainer limit={1} closeOnClick />
    </div>
  );
};

export default Toast;
