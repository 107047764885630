import moment from 'moment/moment';
import 'moment/locale/fr';
import { t } from 'i18next';

const transformDate = (date: any): string => {
  if (date !== undefined && !isNaN(new Date(date).getDate())) {
    const missionDate = new Date(date);

    const day = missionDate.getUTCDate();
    const month = missionDate.getUTCMonth() + 1; // Les mois commencent à partir de 0

    const gmtDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}`;

    return gmtDate;
  }
  return '';
};

const transformDateFormat = (date: any, language = 'en'): string => {
  if (date != undefined && !isNaN(new Date(date).getDate())) {
    const missionDate = new Date(date);
    const momentDate = moment(missionDate);
    momentDate.locale(language);

    return momentDate.format(t('date.format'));
  }
  return '';
}
const transformDateSimpleFormat = (date: any, language = 'en'): string => {
  if (date != undefined && !isNaN(new Date(date).getDate())) {
    const missionDate = new Date(date);
    const momentDate = moment(missionDate);
    momentDate.locale(language);

    return momentDate.format(t('date.simple'));
  }
  return '';
}

const transformTime = (datetime: Date | undefined) => {
  if (datetime !== undefined && !isNaN(new Date(datetime).getDate())) {
    const date = new Date(datetime);
    let hours = date.getUTCHours().toString();
    if (date.getUTCHours() < 10) {
      hours = '0' + hours.toString();
    }
    let minutes = date.getUTCMinutes().toString();
    if (date.getUTCMinutes() < 10) {
      minutes = '0' + minutes.toString();
    }
    return `${hours}:${minutes}`;
  }
  return '';
};

const convertTimeToDateGMT = (timeString: string) => {
  // Récupérer les heures et les minutes de la chaîne de temps
  const [hours, minutes] = timeString.split(':').map((str) => parseInt(str, 10));

  // Créer une nouvelle date en utilisant la date de référence (1er janvier 1970) et en spécifiant les heures et les minutes
  const gmtDate = new Date(Date.UTC(1970, 0, 1, hours, minutes));

  return gmtDate;
};

export { transformDate, transformDateFormat, transformDateSimpleFormat, transformTime, convertTimeToDateGMT };
